import {
    Box,
    Heading,
    useToast,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
    Skeleton,
    Toast,
    Select,
} from "@chakra-ui/react"

import { Field } from '../../types/fieldInterface';

const soil_type_presets = [
    { label: 'Sand', sand: 90, clay: 5, silt: 5, som: 1.5 },
    { label: 'Loamy Sand', sand: 80, clay: 10, silt: 10, som: 1.5 },
    { label: 'Sandy Loam', sand: 65, clay: 10, silt: 25, som: 3 },
    { label: 'Sandy Clay Loam', sand: 55, clay: 20, silt: 25, som: 3.5 },
    { label: 'Loam', sand: 40, clay: 20, silt: 40, som: 4.5 },
    { label: 'Silt Loam', sand: 20, clay: 15, silt: 65, som: 4.5 },
    { label: 'Silt', sand: 10, clay: 10, silt: 80, som: 5.5 },
    { label: 'Silty Clay Loam', sand: 10, clay: 30, silt: 60, som: 5 },
    { label: 'Clay', sand: 20, clay: 40, silt: 40, som: 4.5 },
    { label: 'Clay Loam', sand: 30, clay: 35, silt: 35, som: 5 },
    { label: 'Sandy Clay', sand: 45, clay: 35, silt: 20, som: 3.5 },
    { label: 'Silty Clay', sand: 10, clay: 45, silt: 45, som: 5.5 },
];

export default function SoilSettingsTab({ field, setField }: { field: Field, setField: React.Dispatch<React.SetStateAction<Field>> }) {
    const toast = useToast();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: keyof Field) => {
        const value = parseFloat(e.target.value);
        if (key === 'soil_texture_sand') {
            if (field.soil_texture_clay && value) {
                const silt = 100 - value - field.soil_texture_clay;
                setField({ ...field, [key]: value, soil_texture_silt: silt });
            }
            else {
                setField({ ...field, [key]: value });
            }
        }
        else if (key === 'soil_texture_clay') {
            if (field.soil_texture_sand && value) {
                const silt = 100 - field.soil_texture_sand - value;
                setField({ ...field, [key]: value, soil_texture_silt: silt });
            }
            else {
                setField({ ...field, [key]: value });
            }
        }
        else {
            setField({ ...field, [key]: e.target.value });
        }
    };

    const handlePresetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedPreset = soil_type_presets.find(preset => preset.label === e.target.value);
        if (selectedPreset) {
            setField({
                ...field,
                soil_texture_sand: selectedPreset.sand,
                soil_texture_clay: selectedPreset.clay,
                soil_texture_silt: selectedPreset.silt,
                soil_texture_organic_matter: selectedPreset.som,
            });
        }
    }

    return (
        <Container maxW="container.xl">
            <Heading size="lg" mb={4}>Soil Settings</Heading>
            <Text mb={4}>
                Configure soil texture and organic matter settings for your field. Select a predefined soil texture class or manually input values. Using soil samples will always be more accurate.
            </Text>
            <VStack spacing={4} align="stretch">
                <Box>
                    <VStack spacing={4} align="stretch">
                    <Box>
                    <FormLabel>Choose Soil Texture Class</FormLabel>
                    <Select placeholder="Select soil texture class" onChange={(e) => handlePresetChange(e)}>
                        {soil_type_presets.map((preset) => (
                            <option key={preset.label} value={preset.label}>
                                {preset.label}
                            </option>
                        ))}
                    </Select>
                </Box>
                        <Box>
                            <FormLabel>Soil Texture Sand (%)</FormLabel>
                            <Input
                                type="number"
                                placeholder="Soil Texture Sand"
                                value={field?.soil_texture_sand || ''}
                                onChange={(e) => handleInputChange(e, 'soil_texture_sand')}
                            />
                        </Box>
                        <Box>
                            <FormLabel>Soil Texture Clay (%)</FormLabel>
                            <Input
                                type="number"
                                placeholder="Soil Texture Clay"
                                value={field?.soil_texture_clay || ''}
                                onChange={(e) => handleInputChange(e, 'soil_texture_clay')}
                            />
                        </Box>
                        <Box>
                            <FormLabel>Soil Texture Silt (%)</FormLabel>
                            <Input
                                type="number"
                                placeholder="Soil Texture Silt"
                                value={field?.soil_texture_silt || ''}
                                onChange={(e) => handleInputChange(e, 'soil_texture_silt')}
                                isReadOnly
                                isDisabled
                            />
                        </Box>
                        <Box>
                            <FormLabel>Soil Texture Organic Matter (%)</FormLabel>
                            <Input
                                type="number"
                                placeholder="Soil Texture Organic Matter"
                                value={field?.soil_texture_organic_matter || ''}
                                onChange={(e) => handleInputChange(e, 'soil_texture_organic_matter')}
                            />
                        </Box>
                    </VStack>
                </Box>
            </VStack>
        </Container>
    );
}