import {
    Text,
    Box,
    Icon,
} from "@chakra-ui/react";

import { useColorModeValue } from "@chakra-ui/react";

import { FaTemperatureHigh } from "react-icons/fa";
import { WiHumidity } from "react-icons/wi";
import { GiSeedling, GiWateringCan, GiMinerals, GiDrop, GiDroplets } from "react-icons/gi";
import { BiTimeFive } from "react-icons/bi";
import { HiCheckCircle } from "react-icons/hi";
import { ImConnection } from "react-icons/im";
import { FaSeedling } from "react-icons/fa";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { BsSun } from "react-icons/bs";
import { GoTextSize } from "react-icons/go";
import { FaBatteryFull } from "react-icons/fa";

const getColorFromStatus = (status: string) => {
    switch (status) {
        case "Good":
            return "green.500";
        case "Very high":
            return "red.500";
        case "High":
            return "blue.400";
        case "Low":
            return "yellow.500";
        case "Very low":
            return "red.500";
        case "Unknown":
            return "gray.500";
        default:
            return "gray.500";
    }
};

const getColorFromLastSeen = (lastSeen: string) => {
    if (lastSeen === "No data") {
        return "red.500";
    }
    const lastSeenDate = new Date(lastSeen);
    const now = new Date();
    const diff = now.getTime() - lastSeenDate.getTime();
    const diffInMinutes = Math.floor(diff / 1000 / 60);
    if (diffInMinutes > 120) {
        return "yellow.500";
    } else {
        return "green.500";
    }
};

const parseDateToString = (dateString: string) => {
    const date = new Date(dateString);
    const datePart = date.toLocaleString("default", { day: "numeric", month: "short" });
    const timePart = date.toLocaleString("default", { hour: "2-digit", minute: "2-digit", hour12: false });
    return datePart + " " + timePart;
};

export const TemperatureParameter = ({ autoplant }: any) => {
    return (
        <Box px="3">
            <Icon
                boxSize="7"
                color={getColorFromStatus(autoplant.parameter_status?.temperature)}
                as={FaTemperatureHigh}
            />
            <Text>{autoplant.device_data?.temperature.toFixed(1)}'C</Text>
        </Box>
    );
}

export const HumidityParameter = ({ autoplant }: any) => {
    return (
        <Box px="3">
            <Icon
                boxSize="7"
                color={getColorFromStatus(autoplant.parameter_status?.humidity)}
                as={WiHumidity}
            />
            <Text>{autoplant.device_data?.humidity.toFixed(0)} %</Text>
        </Box>
    );
}

export const LightParameter = ({ autoplant }: any) => {
    return (
        <Box px="3">
            <Icon
                boxSize="7"
                color={getColorFromStatus(autoplant.parameter_status?.light)}
                as={BsSun}
            />
            <Text>{autoplant.device_data?.lux.toFixed(0)} lux</Text>
        </Box>
    );
}


export const LastSeenParameter = ({ autoplant }: any) => {
    return (
        <Box px="3">
            <Icon boxSize="7" color={getColorFromLastSeen(autoplant.last_seen)} as={ImConnection} />
            {autoplant.last_seen === "No data" ? (
                <Text>No data</Text>
            ) : (

                <Text>{parseDateToString(autoplant?.last_seen)} </Text>
            )}
        </Box>
    );
}



export const MoistureParameter = ({ autoplant }: any) => {
    return (
        <Box px="3">
            <Icon
                boxSize="7"
                color={getColorFromStatus(autoplant.parameter_status?.moisture)}
                as={FaSeedling}
            />
            <Text>Moisture</Text>
            <Text>{autoplant.device_data?.moisture.toFixed(0)} %</Text>
        </Box>
    );
}


export const NutrientsParameter = ({ autoplant }: any) => {
    return (
        <Box px="3">
            <Icon boxSize="7" color={getColorFromStatus(autoplant.parameter_status?.nutrients)} as={GiMinerals} />
            <Text>Nutrients</Text>
            <Text>{autoplant.device_data?.nutrient_level.toFixed(1)}</Text>
        </Box>
    );
}


export const ActiveParameter = ({ autoplant }: any) => {
    return (
        <Box px="2">
            <Icon
                boxSize="7"
                color={
                    autoplant.device_data.regulator_is_enabled ? (
                        "green.500"
                    ) : (
                        "gray.500"
                    )}
                as={HiCheckCircle}
            />
            <Text>Active</Text>
            {autoplant.device_data.regulator_is_enabled ? (
                <Text>Yes</Text>
            ) : (
                <Text>No</Text>
            )}
        </Box>
    );
}


export const LastWateredParameter = ({ autoplant }: any) => {
    const iconColor = useColorModeValue("gray.600", "gray.400");
    return (
        <Box px="2">
            <Icon boxSize="7" color={iconColor} as={BiTimeFive} />
            { // Calculate time since last watering
                !autoplant.device_data?.last_watered_at ? (
                    <Text>No data</Text>
                ) : (
                    <Text>{parseDateToString(autoplant.device_data?.last_watered_at)}</Text>
                )

            }
        </Box>
    );
}


export const WateredAmountParameter = ({ autoplant }: any) => {
    const iconColor = useColorModeValue("gray.600", "gray.400");
    return (
        <Box px="2">
            <Icon boxSize="7" color={iconColor} as={GiDrop} />
            <Text>Amount</Text>
            <Text>{(autoplant.device_data?.last_watered_amount * 0.022 * 10).toFixed(1)} dl</Text>
        </Box>
    );
}


export const TotalWateredParameter = ({ autoplant }: any) => {
    const iconColor = useColorModeValue("gray.600", "gray.400");
    return (
        <Box px="2">
            <Icon boxSize="7" color={iconColor} as={GiDroplets} />
            <Text>Total</Text>
            <Text>{(autoplant.device_data?.cumulative_watered_amount * 0.022).toFixed(1)} l</Text>
        </Box>
    );
}

export const CurrentSpeciesParameter = ({ autoplant }: any) => {
    // Include latin name as well
    const iconColor = useColorModeValue("gray.600", "gray.400");
    return (
        <Box px="2">
            <Icon boxSize="7" color={iconColor} as={FaSeedling} />
            <Text>{autoplant.parameter_status?.plant_type}</Text>
        </Box>
    );
}

export const NicknameParameter = ({ autoplant }: any) => {
    const iconColor = useColorModeValue("gray.600", "gray.400");
    return (
        <Box px="2">
            <Icon boxSize="7" color={iconColor} as={GoTextSize} />
            <Text>{autoplant.device_data.device_name}</Text>
        </Box>
    );
}

export const BatteryStatusParameter = ({ autoplant }: any) => {
    // TODO: Add different icons for different battery levels
    const iconColor = useColorModeValue("gray.600", "gray.400");
    return (
        <Box px="2">
            <Icon boxSize="7" color={iconColor} as={FaBatteryFull} />
            <Text>Battery</Text>
            <Text>{autoplant.device_data.battery.toFixed(1)} V</Text>
        </Box>
    );
}