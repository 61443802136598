import React from 'react';
import { Box, Heading, Text, Badge, useColorModeValue, VStack, HStack, Skeleton } from "@chakra-ui/react";
import { IDevice } from '../views/DeviceOverview';


const DataDump = ({ device }: { device: IDevice | undefined }) => {

    const timestampColor = useColorModeValue('gray.600', 'gray.400');

    if (!device) {
        return (
            <>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
            </>
        );
    }

    if (Object.keys(device.data).length === 0) {
        return (
            <Text>Current data unavailable</Text>
        );
    }

    // Convert timestamp string to Date object and format it
    const timestamp = new Date(device.data.timestamp).toLocaleString('default', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });

    return (
        <>
            {device.data.soil_moisture !== null && device.data.soil_moisture !== undefined && <Text>Soil Moisture: {device.data.soil_moisture.toFixed(1)} % </Text>}
            {device.data.soil_temperature !== null && device.data.soil_temperature !== undefined && <Text>Soil Temperature: {device.data.soil_temperature.toFixed(1)} ℃</Text>}
            {device.data.soil_conductivity !== null && device.data.soil_conductivity !== undefined && <Text>Soil Conductivity: {device.data.soil_conductivity.toFixed(0)}</Text>}
            {device.data.temperature !== null && device.data.temperature !== undefined && <Text>Air temperature: {device.data.temperature.toFixed(1)} ℃</Text>}
            {device.data.humidity !== null && device.data.humidity !== undefined && <Text>Humidity: {device.data.humidity.toFixed(0)} %</Text>}
            {device.data.lux !== null && device.data.lux !== undefined && <Text>Lux: {device.data.lux.toFixed(0)}</Text>}
            {device.data.battery_voltage !== null && device.data.battery_voltage !== undefined && <Text>Battery Voltage: {device.data.battery_voltage.toFixed(2)} V </Text>}
            {device.data.battery !== null && device.data.battery !== undefined && <Text>Battery Voltage: {device.data.battery.toFixed(2)} V </Text>}
            <Text color={timestampColor} fontSize="sm">Last Updated: {timestamp}</Text>
        </>
    );
}

const deviceTypeLookup = new Map<number, string>([
    [-1, 'DRAGINO'],
    [0, 'SENSOR'],
    [1, 'SENSOR'],
    [2, 'AUTOPLANT'],
    [3, 'ENVIRONMENT'],
    [4, 'RELAY'],
    [5, 'VALVE'],
    [6, 'VALVE_WITH_FLOW_SENSOR'],
    [7, 'PUMP'],
]);

const DeviceTile = ({ device }: { device: IDevice | undefined }) => {
    const boxColor = useColorModeValue('white', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'gray.200');

    if (!device) {
        return (
            <Box height={300}>
                <VStack align="start" spacing={4}>
                    <HStack spacing={4}>
                        <Skeleton height="20px" />
                        <Skeleton height="20px" />
                        <Skeleton height="20px" />
                        <Skeleton height="20px" />
                        <Skeleton height="20px" />
                        <Skeleton height="20px" />
                    </HStack>
                </VStack>
            </Box>
        );
    }

    console.log("Device name", device.device_name);
    const deviceType = deviceTypeLookup.get(device?.device_type ?? 0) || 'UNKNOWN';


    return (
            <VStack align="start" spacing={4}>
                <HStack spacing={4}>
                    <Heading size="md">{device.device_name ? device.device_name : "Unnamed device"}</Heading>
                    <Badge colorScheme="green">{deviceType}</Badge>
                </HStack>
                <Heading size="sm" mb={2}>Current Data:</Heading>
                <DataDump device={device} />
            </VStack>
    );
}

export { DataDump };
export default DeviceTile;