import { Route, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import AuthContext from '../context/AuthContext';
import { Outlet } from 'react-router-dom';

const PrivateRoute = ({ ...rest }) => {
    // TODO: Use the AuthContext to check if the user is logged in
    const history = useNavigate();

    useEffect(() => {
        // First check if the token exists in localstorage
        if (!localStorage.getItem('token')) {
            // If it doesn't, redirect to login
            history('/login');
        }
    }, []);

    return (<Outlet />);
};

export default PrivateRoute;
