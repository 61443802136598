import area from '@turf/area';

import { Box, Text } from '@chakra-ui/react';

function ControlPanel(props) {
  // We will keep this here if we need it later
  let polygonArea = 0;
  for (const polygon of props.polygons) {
    polygonArea += area(polygon);
  }

  console.log("Polygon area", polygonArea);

  return (
    <>
    {/* <Box>
      <Text>Field area</Text>
      {polygonArea > 0 && (
        <Text>
          {Math.round(polygonArea * 100) / 100} m^2
        </Text>
      )}
    </Box> */}
    </>
  );
}

export default ControlPanel;