import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export default AuthContext;



export const AuthProvider = ({ children }) => {
  // TODO: Add a state variable to keep track of the user's login status
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("token")
      ? localStorage.getItem("token")
      : null
  );
  // Fetch user here
  const [user, setUser] = useState({});

  const [userSettings, setUserSettings] = useState({});

  const [loading, setLoading] = useState(true);

  const history = useNavigate();

  const fetchUser = async () => {
    console.log("")
    const response = await fetch(process.env.REACT_APP_API_URL + "api/v1/users/auth/user/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authTokens}`,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      setUser(data);
    }
    else {
      console.log(response);
      history("/login");
    }
  };

  const fetchUserSettings = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "api/v1/frontend/frontend-settings/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authTokens}`,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      setUserSettings(data);
    }
    else {
      console.log(response);
      history("/login");
    }
  }

  useEffect(() => {
    if (authTokens) {
      fetchUser();
      fetchUserSettings();
    }
  }, []);

  const loginUser = async (email, password) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "api/v1/users/auth/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        password
      })
    });
    const data = await response.json();

    if (response.status === 200) {
      setAuthTokens(data);
      localStorage.setItem("token", data.key);
      history("/");
      return data;
    } else {
      return data;
    }
  };

  const registerUser = async (email, first_name, last_name, password1, password2) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "api/v1/users/auth/register/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        first_name,
        last_name,
        password1,
        password2
      })
    });
    if (response.status === 201) {
      setAuthTokens(response.data);
      history("/login");
      return response;
    } else {
      return response;
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    localStorage.removeItem("token");
    history("/login");
  };

  const contextData = {
    user,
    userSettings,
    authTokens,
    setAuthTokens,
    registerUser,
    loginUser,
    logoutUser
  };

  useEffect(() => {
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
