import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";
import { useState, useEffect } from 'react';
import { useToast } from "@chakra-ui/react";
import { Container, Box, VStack, StackDivider, useColorModeValue, Heading, Text, Radio, RadioGroup, Skeleton } from "@chakra-ui/react";
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';


const fullDateTimeFormat: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
}

const dateOnlyFormat: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
}


interface IDeviceData {
    device_address: string;
    version: string;
    battery: number;
    signal_strength: number;
    mod: number;
    interrupt: number;
    soil_moisture: number;
    soil_temperature: number;
    soil_conductivity: number;
    soil_dielectric_constant: number;
    timestamp: string;  // Can be Date if directly working with Date objects
}

interface IPlottableDeviceData {
    soil_moisture: Array<number>;
    soil_temperature: Array<number>;
    timestamp: Array<string>;  // Can be Date if directly working with Date objects
}

const DraginoViewer = () => {
    const api = useAxios();
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(true);
    const [DraginoDevice, setDraginoDevice] = useState<IDeviceData | null>(null);

    const [chartDataIsLoading, setChartDataIsLoading] = useState(true);
    const [chartData, setChartData] = useState<IPlottableDeviceData | null>(null);
    const [days, setDays] = useState<number>(7);  // Default to 7 days
    const text_color = useColorModeValue('gray.800', 'gray.100');
    const heading_color = useColorModeValue('teal.500', 'teal.300');
    const box_color = useColorModeValue('white', 'gray.800')

    const params = useParams<Record<string, string>>()

    console.log(params.id);

    const fetchChartData = async () => {
        try {
            let aggregate_daily = false;
            if (days > 7) {
                aggregate_daily = true;
            }
            let response = await api.get(
                "interface/get-plottable-dragino-data/" + params.id + "/",
                {
                    params: {
                        days: days,
                        aggregate_daily: aggregate_daily
                    }
                }
            );
            response.data.soil_moisture.reverse()
            response.data.soil_temperature.reverse()
            response.data.timestamp.reverse()
            console.log(response.data);
            setChartData(response.data);

            setChartDataIsLoading(false);
        } catch {
            setChartData(null);
            setChartDataIsLoading(false);
            // Toast
            toast({
                title: "Error fetching data",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        const fetchLatestData = async () => {
            try {
                const response = await api.get("interface/get-latest-dragino-data/" + params.id + "/");
                console.log(response.data);
                setDraginoDevice(response.data);

                setIsLoading(false);
            } catch {
                setDraginoDevice(null);
                setIsLoading(false);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        };



        fetchLatestData();
        fetchChartData();
    }, []);

    // Fetch new plot data when days changes
    useEffect(() => {
        setChartDataIsLoading(true);
        fetchChartData();
    }, [days]);

    return (
        <Navbar>
            <Box mx={{ base: 2, sm: 4, md: 6 }}>
                <VStack
                    mt={2}
                    spacing={2}
                    align="stretch"
                    divider={
                        <StackDivider
                            borderColor={useColorModeValue('gray.200', 'gray.600')}
                        />
                    }>
                    <Skeleton isLoaded={!isLoading}>
                        <Box
                            width={"500"}
                            height={"500"}
                            fontSize="large"
                        >

                            <Container>
                                <Box padding="5" bg={box_color} boxShadow="md" borderRadius="md" mb={4}>
                                    <Heading as="h2" size="lg" mb={4} color={heading_color}>
                                        Device: {DraginoDevice?.device_address}
                                    </Heading>
                                    <Heading as="h3" size="md" mb={2}> Current conditions </Heading>
                                    <Text mb={2} fontSize="md" color={text_color}>Battery: {DraginoDevice?.battery} V </Text>
                                    <Text mb={2} fontSize="md" color={text_color}>Signal strength: {DraginoDevice?.signal_strength}</Text>
                                    <Text mb={2} fontSize="md" color={text_color}>Soil moisture: {DraginoDevice?.soil_moisture} % (VWC) </Text>
                                    <Text mb={2} fontSize="md" color={text_color}>Soil temperature: {DraginoDevice?.soil_temperature}  ℃ </Text>
                                    <Text mb={2} fontSize="md" color={text_color}>Soil conductivity: {DraginoDevice?.soil_conductivity} EC </Text>
                                    <Text mb={2} fontSize="md" color={text_color}>Soil dielectric constant: {DraginoDevice?.soil_dielectric_constant} Ka </Text>
                                    <Text mb={2} fontSize="md" color={text_color}>
                                        Timestamp: {DraginoDevice?.timestamp ? new Date(DraginoDevice.timestamp).toLocaleDateString()
                                            + ' '
                                            + new Date(DraginoDevice.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A'}
                                    </Text>
                                </Box>
                            </Container>


                            <Box>
                                <Box padding="5" bg={box_color} boxShadow="md" borderRadius="md">
                                    <Heading as="h2" size="lg" mb={4} color={heading_color}>
                                        Chart
                                    </Heading>
                                    <RadioGroup
                                        onChange={(value: string) => setDays(Number(value))}
                                        value={days.toString()}
                                    >
                                        <Radio mr={3} value={"1"}>1 day</Radio>
                                        <Radio mr={3} value={"7"}>7 days</Radio>
                                        <Radio mr={3} value={"30"}>30 days</Radio>
                                        <Radio value={"365"}>365 days</Radio>
                                    </RadioGroup>
                                    {
                                        chartData && !chartDataIsLoading
                                            ? (
                                                <>
                                                    <Box mb={6}>
                                                        <Line
                                                            data={{
                                                                labels: chartData.timestamp.map(
                                                                    (timestamp: string) => new Date(timestamp).toLocaleDateString(
                                                                        'nb-NO', days > 7 ? dateOnlyFormat : fullDateTimeFormat
                                                                    )
                                                                ),
                                                                datasets: [
                                                                    {
                                                                        label: 'Soil Moisture',
                                                                        data: chartData.soil_moisture,
                                                                        fill: false,
                                                                        backgroundColor: 'rgb(75, 192, 192)',
                                                                        borderColor: 'rgba(75, 192, 192, 0.2)',
                                                                    },
                                                                ],
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Line
                                                            data={{
                                                                labels: chartData.timestamp.map(
                                                                    (timestamp: string) => new Date(timestamp).toLocaleDateString(
                                                                        'nb-NO', days > 7 ? dateOnlyFormat : fullDateTimeFormat
                                                                    )
                                                                ),
                                                                datasets: [
                                                                    {
                                                                        label: 'Soil Temperature',
                                                                        data: chartData.soil_temperature,
                                                                        fill: false,
                                                                        backgroundColor: 'rgb(255, 99, 132)',
                                                                        borderColor: 'rgba(255, 99, 132, 0.2)',
                                                                    },
                                                                ],
                                                            }}
                                                        />
                                                    </Box>
                                                </>

                                            )
                                            : <p>Loading...</p>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Skeleton>
                </VStack>
            </Box >
        </Navbar >
    )
}

export default DraginoViewer;