import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    Stack,
    List,
    ListItem,
    useColorModeValue,
    StackDivider,
    Select,
    Flex,
    Spacer,
    Center,
    VStack,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import useAxios from "../../utils/useAxios"
import Navbar from "../../components/Navbar";
import ConfigurePlantTile from "../../components/Legacy/ConfigurePlantTile";
import FancyPageLoadingAnymation from "../../components/FancyPageLoadingAnymation";


const AutoPlantSelect = ({ autoplants, selectedAutoPlant, setSelectedAutoPlant }: any) => {
    return (
        <Select
            onChange={(e) => { setSelectedAutoPlant(e.target.value) }}
            placeholder="Select a plant"
            value={selectedAutoPlant || ''}
            size="lg"
            variant="outline"
            color={useColorModeValue('gray.900', 'gray.400')}
            bg={useColorModeValue('white', 'gray.800')}
            borderColor={useColorModeValue('gray.200', 'gray.600')}
            borderRadius="lg"
            borderWidth="1px"
            _hover={{ borderColor: "gray.300" }}
            _focus={{ borderColor: "gray.300" }}
            _disabled={{ opacity: 0.5 }}
            isDisabled={autoplants.length === 0}
        >
            {autoplants?.map((autoplant: any) => (
                <option key={autoplant.id} value={autoplant.id}>
                    {autoplant.device_name}
                </option>
            ))}
        </Select>
    );
}

const AutoPlantInfo = () => {

    const [autoplants, setAutoplants] = useState([]);
    const [selectedAutoPlant, setSelectedAutoPlant] = useState<any>("");
    const [autoplantData, setAutoPlantData] = useState<any>({});

    const [searchParams, setSearchParams] = useSearchParams();

    const api = useAxios();

    // Colors
    const secondaryColor = useColorModeValue('gray.900', 'white')
    const subHeaderColor = useColorModeValue('gray.500', 'gray.400')

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("users/autoplant-devices/autoplant-devices/");
                setAutoplants(response.data);
            } catch {
                setAutoplants([]);
                // Raise error?
            }
        };
        fetchData();
        setSelectedAutoPlant(searchParams.get("autoplant-id"));
    }, []);

    useEffect(() => {
        // Fetch data when autoplant is selected
        if (selectedAutoPlant) {
            const fetchData = async () => {
                try {
                    const response = await api.get(`/frontend/autoplant-detail/${selectedAutoPlant}`);
                    setAutoPlantData(response.data);
                } catch {
                    setAutoPlantData({});
                    // Raise error?
                }
            };
            fetchData();
        } else {
            setAutoPlantData({});
        }
    }, [selectedAutoPlant]);

    console.log(autoplantData);

    return (
        <Navbar>
            <Box mx={{ base: 2, sm: 4, md: 6 }}>
                <Stack
                    spacing={{ base: 2, sm: 3 }}
                    direction={'column'}
                    divider={
                        <StackDivider
                            borderColor={useColorModeValue('gray.200', 'gray.600')}
                        />
                    }>
                    <VStack p={4}>
                        <Box flexShrink={0} width={{ base: "100%", lg: "70%", xl: "60%" }}>
                            <AutoPlantSelect
                                autoplants={autoplants}
                                selectedAutoPlant={selectedAutoPlant}
                                setSelectedAutoPlant={setSelectedAutoPlant} />
                        </Box>
                        <Heading
                            lineHeight={1.1}
                            fontWeight={600}
                            color={secondaryColor}
                            pt="3"
                            fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}>

                            {(Object.keys(autoplantData).length > 0) ? autoplantData.parameter_status?.plant_type : 'No plant selected'}

                        </Heading>
                        <Text
                            color={subHeaderColor}
                            fontSize={'2xl'}
                            fontWeight={'300'}
                        >
                            {autoplantData.device_data?.device_name}
                        </Text>
                    </VStack>
                    {(!Object.keys(autoplantData).length && selectedAutoPlant) ?
                        <FancyPageLoadingAnymation />
                        :
                        (
                            <>
                                {Object.keys(autoplantData).length !== 0 && (
                                    <SimpleGrid
                                        minChildWidth={{ base: '100%', md: '25em' }}
                                        spacing={{ base: 2, md: 3 }}
                                        py={3}
                                    >
                                        <ConfigurePlantTile autoplant={autoplantData} />
                                    </SimpleGrid>
                                )}
                            </>
                        )
                    }
                </Stack>
            </Box>
        </Navbar>
    );
}

export default AutoPlantInfo;

