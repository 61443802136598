import { useEffect, useState, useContext } from 'react';

import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
    Skeleton,
    Toast,
    Switch,
    Radio,
    RadioGroup,
    border,
} from "@chakra-ui/react"

import { Line } from 'react-chartjs-2';

import { Field } from '../../types/fieldInterface';

import useAxios from "../../utils/useAxios";

interface IMoistureData {
    timestamp: string[];
    soil_moisture_5?: Array<number|null>;
    soil_moisture_10?: Array<number|null>;
    soil_moisture_15?: Array<number|null>;
    soil_moisture_20?: Array<number|null>;
    soil_moisture_30?: Array<number|null>;
    soil_moisture_40?: Array<number|null>;
    rain?: Array<number|null>;
    temp?: Array<number|null>;
    clouds?: Array<number|null>;
}

const hourlyDateTimeFormat: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit'
}


const dateOnlyFormat: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
}


interface TooltipItem {
    datasetIndex: number;
    index: number;
    xLabel: string;
    yLabel: number;
    label: string;
    value: string;
}

interface Data {
    datasets: {
        label: string;
    }[];
}

const MoistureWidget: React.FC<{ field: Field }> = ({ field }) => {
    const [moistureData, setMoistureData] = useState<IMoistureData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [days, setDays] = useState<number>(30);
    const toast = useToast();
    const api = useAxios();


    useEffect(() => {
        const fetchData = async () => {
            let dateFormat: Intl.DateTimeFormatOptions;
            let aggregate = "none";
            if (days > 30) {
                aggregate = "date";
                dateFormat = dateOnlyFormat;
            }
            else {
                aggregate = "hour";
                dateFormat = hourlyDateTimeFormat;
            }
            try {
                let response = await api.get(
                    `agtech/fields/${field.id}/moisture/?aggregate=${aggregate}&days=${days}&include_weather=true`
                );

                response.data.timestamp = response.data.timestamp?.map(
                    (timestamp: string) => new Date(timestamp).toLocaleDateString(
                        'nb-NO', dateFormat
                    )
                );

                console.log(response.data);
                setMoistureData(response.data);
                setLoading(false);
            } catch (error: any) {
                // Toast
                toast({
                    title: 'Error fetching data',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        if (field) {
            fetchData();
        }
    }, [field, days]);


    if (!moistureData) {
        return (
            <Skeleton height="400px" width="100%" />
        );
    }


    const datasets = [
        {
            label: '5cm',
            data: moistureData?.soil_moisture_5,
            fill: true,
            backgroundColor: 'rgb(70, 180, 255, 0.1)', // Very Light Blue
            borderColor: 'rgba(70, 180, 255, 0.99)',
            yAxisID: 'y-axis-1',
            pointRadius: 0,
        },
        {
            label: '10cm',
            data: moistureData?.soil_moisture_10,
            fill: true,
            backgroundColor: 'rgb(54, 162, 235, 0.1)', // Light Blue
            borderColor: 'rgba(54, 162, 235, 0.99)',
            yAxisID: 'y-axis-1',
            pointRadius: 0,
        },
        {
            label: '15cm',
            data: moistureData?.soil_moisture_15,
            fill: true,
            backgroundColor: 'rgb(0, 123, 255, 0.1)', // Blue
            borderColor: 'rgba(0, 123, 255, 0.99)',
            yAxisID: 'y-axis-1',
            pointRadius: 0,
        },
        {
            label: '20cm',
            data: moistureData?.soil_moisture_20,
            fill: true,
            backgroundColor: 'rgb(0, 100, 220, 0.1)', // Blue
            borderColor: 'rgba(0, 123, 255, 0.99)',
            yAxisID: 'y-axis-1',
            pointRadius: 0,
        },
        {
            label: '30cm',
            data: moistureData?.soil_moisture_30,
            fill: true,
            backgroundColor: 'rgb(0, 71, 171, 0.1)', // Dark Blue
            borderColor: 'rgba(0, 71, 171, 0.99)',
            yAxisID: 'y-axis-1',
            pointRadius: 0,
        },
        {
            label: '40cm',
            data: moistureData?.soil_moisture_40,
            fill: true,
            backgroundColor: 'rgb(0, 37, 96, 0.1)', // Very Dark Blue
            borderColor: 'rgba(0, 37, 96, 0.99)',
            yAxisID: 'y-axis-1',
            pointRadius: 0,
        },
        {
            type: 'bar',
            label: 'Rain [mm]',
            data: moistureData?.rain,
            fill: false,
            backgroundColor: 'rgba(0, 0, 240, 0.5)', // Blue
            borderColor: 'rgb(0, 0, 240)',
            yAxisID: 'y-axis-2',
        },
    ];
    const definedDatasets = datasets.filter(dataset => dataset.data !== undefined);
    
    const data = {
        labels: moistureData.timestamp,
        datasets: definedDatasets,
    };

    const options = {
        scales: {
            yAxes:[
                {id: 'y-axis-1', type: 'linear', position: 'left',
                    scaleLabel: {
                        display: true,
                        labelString: 'Moisture (%)' // Label for the second y-axis
                    },
                    ticks: {
                        suggestedMin: 20, suggestedMax: 40
                    },
                },
                {id: 'y-axis-2', type: 'linear', position: 'right',
                scaleLabel: {
                    display: true,
                    labelString: 'Rain (mm)' // Label for the second y-axis
            },
                ticks: {
                    min: 0, suggestedMax: days > 7 ? 20 : 1
                },
            },
            ],
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            position: 'nearest',
            callbacks: {
                label: function(tooltipItem: TooltipItem, data: Data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += tooltipItem.yLabel.toFixed(1);
                    return label;
                }
            },
        }
    };

    return (
        <Box>
            <Heading size="lg">Avg. Field Moisture</Heading>
            <RadioGroup
                onChange={(value: string) => setDays(Number(value))}
                value={days.toString()}
                mt="4"
            >
                <Stack direction="row">
                    <Radio value={"7"}>7 days</Radio>
                    <Radio value={"30"}>30 days</Radio>
                    <Radio value={"90"}>90 days</Radio>
                </Stack>
            </RadioGroup>
            {loading ? (
                <Skeleton height="300px" width="100%" />
            ) : (
                <Line height={180} data={data} options={options} />
            )}
        </Box>
    );
}

export default MoistureWidget;