import {
    Heading,
    Box,
    VStack,
    StackDivider,
    SimpleGrid,
    Icon,
    Grid,
    GridItem,
    useDisclosure,
} from "@chakra-ui/react";

import { useColorModeValue } from "@chakra-ui/react";

import { GiSeedling, GiWateringCan } from "react-icons/gi";
import { TiWeatherPartlySunny } from "react-icons/ti";

import { NotImplementedModal, PlantModal, EnvironmentModal, WateringModal } from "./StatusModals";

import {
    TemperatureParameter,
    HumidityParameter,
    LightParameter,
    LastSeenParameter,
    MoistureParameter,
    NutrientsParameter,
    ActiveParameter,
    LastWateredParameter,
    WateredAmountParameter,
    TotalWateredParameter,

} from "./ParameterStatus";

export const EnvironmentStatusTile = ({ autoplant }: { autoplant: any }) => {
    const iconColor = useColorModeValue("gray.600", "gray.400");
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Box
            p={3}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            bg={useColorModeValue("white", "gray.800")}
            _hover={{
                bg: useColorModeValue("gray.50", "gray.700"),
            }}
            onClick={onOpen}

        >
            <EnvironmentModal isOpen={isOpen} onClose={onClose} autoplant={autoplant} />
            <VStack
                divider={
                    <StackDivider
                        borderColor={useColorModeValue('gray.200', 'gray.600')}
                    />}
            >
                <Grid templateColumns="repeat(3, 1fr)" width="100%" my="-1">
                    <GridItem>
                        <Icon boxSize="10" color={iconColor} as={TiWeatherPartlySunny} />
                    </GridItem>
                    <GridItem>
                        <Heading size="md" pt="2">Environment</Heading>
                    </GridItem>
                </Grid>
                <SimpleGrid columns={3} fontSize={"sm"} width="100%">
                    <TemperatureParameter autoplant={autoplant} />
                    <HumidityParameter autoplant={autoplant} />
                    <LightParameter autoplant={autoplant} />
                </SimpleGrid>
            </VStack>
        </Box>
    );
};

export const SoilStatusTile = ({ autoplant }: { autoplant: any }) => {
    const iconColor = useColorModeValue("gray.600", "gray.400");
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Box
            p={3}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            bg={useColorModeValue("white", "gray.800")}
            _hover={{
                bg: useColorModeValue("gray.50", "gray.700"),
            }}
            onClick={onOpen}
        >
            <PlantModal isOpen={isOpen} onClose={onClose} autoplant={autoplant} />
            <VStack
                divider={
                    <StackDivider
                        borderColor={useColorModeValue('gray.200', 'gray.600')}
                    />}
            >
                <Grid templateColumns="repeat(3, 1fr)" width={"100%"} my="-1">
                    <GridItem>
                        <Icon boxSize="10" color={iconColor} as={GiSeedling} />
                    </GridItem>
                    <GridItem>
                        <Heading size="md" pt="2">Plant</Heading>
                    </GridItem>
                </Grid>
                <SimpleGrid columns={3} fontSize={"sm"} width="100%">
                    <LastSeenParameter autoplant={autoplant} />
                    <MoistureParameter autoplant={autoplant} />
                    <NutrientsParameter autoplant={autoplant} />
                </SimpleGrid>
            </VStack>
        </Box>
    );
};

export const WateringStatusTile = ({ autoplant }: { autoplant: any }) => {
    const iconColor = useColorModeValue("gray.600", "gray.400");
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Box
            p={3}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            bg={useColorModeValue("white", "gray.800")}
            _hover={{
                bg: useColorModeValue("gray.50", "gray.700"),
            }}
            onClick={onOpen}
        >
            <WateringModal isOpen={isOpen} onClose={onClose} autoplant={autoplant} />
            <VStack
                divider={
                    <StackDivider
                        borderColor={useColorModeValue('gray.200', 'gray.600')}
                    />}
            >
                <Grid templateColumns="repeat(3, 1fr)" width={"100%"} my="-1">
                    <GridItem>
                        <Icon boxSize="10" color={iconColor} as={GiWateringCan} />
                    </GridItem>
                    <GridItem>
                        <Heading size="md" pt="2">Watering</Heading>
                    </GridItem>
                </Grid>
                <SimpleGrid columns={4} fontSize={"sm"} width="100%">
                    <ActiveParameter autoplant={autoplant} />
                    <LastWateredParameter autoplant={autoplant} />
                    <WateredAmountParameter autoplant={autoplant} />
                    <TotalWateredParameter autoplant={autoplant} />
                </SimpleGrid>
            </VStack>
        </Box>
    );
};