import { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
} from "@chakra-ui/react";

import Navbar from '../../components/Navbar';
import useAxios from "../../utils/useAxios";

interface ITrainingData {
    device_address: string;
    real_moisture?: number;
    soil_category?: number;
    nutrient_level?: number;
    sensor_version?: number;
    measurement_time_minutes?: number;
}

const AddTrainingDataView = () => {
    const toast = useToast();
    const api = useAxios();

    const [deviceAddress, setDeviceAddress] = useState('');
    const [moisture, setMoisture] = useState<number | undefined>(undefined);
    const [soilCategory, setSoilCategory] = useState<number | undefined>(undefined);
    const [nutrientLevel, setNutrientLevel] = useState<number | undefined>(undefined);
    const [deviceVersion, setDeviceVersion] = useState<number | undefined>(undefined);
    const [measurementTime, setMeasurementTime] = useState<number | undefined>(15);

    const handleSubmit = async () => {
        if (deviceAddress && moisture !== null && deviceVersion !== null) {
            const trainingData: ITrainingData = {
                device_address: deviceAddress,
                real_moisture: moisture,
                soil_category: soilCategory,
                nutrient_level: nutrientLevel,
                sensor_version: deviceVersion,
                measurement_time_minutes: measurementTime
            }
            try {
                const response = await api.post('ml/create-soil-training-sample/', trainingData)
                setMoisture(undefined);
                setNutrientLevel(undefined);
                toast({
                    title: "Data submitted successfully. "
                        + response.data.measurements
                        + " samples logged.",
                    status: "success"
                })
                console.log(response.data)
            } catch (err: any) {
                console.log(err)
                toast({ title: err.message || "An error occurred", status: "error" })
            }
        }
    }

    return (
        <Navbar>
            <Container p="4" mt="4" borderRadius={8} bg={useColorModeValue('white', 'gray.800')}>
                <Heading>Add Training Data</Heading>
                <Text my="2"> Log a training sample based on the last x minutes </Text>
                <FormControl id="deviceAddress" isRequired>
                    <FormLabel>Device Address</FormLabel>
                    <Input
                        type="text"
                        value={deviceAddress}
                        onChange={e => setDeviceAddress(e.target.value)}
                    />
                </FormControl>
                <FormControl id="deviceVersion" isRequired>
                    <FormLabel>Device Version</FormLabel>
                    <Input
                        type="number"
                        value={deviceVersion}
                        onChange={e => setDeviceVersion(e.target.value ? Number(e.target.value) : undefined)}
                    />
                </FormControl>
                <FormControl id="soilCategory">
                    <FormLabel>Soil Category</FormLabel>
                    <Input
                        type="number"
                        value={soilCategory}
                        onChange={e => setSoilCategory(e.target.value ? Number(e.target.value) : undefined)}
                    />
                </FormControl>
                <FormControl id="moisture" isRequired>
                    <FormLabel>Moisture</FormLabel>
                    <Input
                        type="number"
                        value={moisture === undefined ? '' : moisture}
                        onChange={e => setMoisture(e.target.value ? Number(e.target.value) : undefined)}
                    />
                </FormControl>

                <FormControl id="nutrientLevel">
                    <FormLabel>Nutrient Level</FormLabel>
                    <Input
                        type="number"
                        value={nutrientLevel === undefined ? '' : nutrientLevel}
                        onChange={e => setNutrientLevel(e.target.value ? Number(e.target.value) : undefined)}
                    />
                </FormControl>

                <FormControl id="measurementTime">
                    <FormLabel>Measurement Time (minutes)</FormLabel>
                    <Input
                        type="number"
                        value={measurementTime === undefined ? '' : measurementTime}
                        onChange={e => setMeasurementTime(e.target.value ? Number(e.target.value) : undefined)}
                    />
                </FormControl>

                <Button mt="2" colorScheme="green" onClick={handleSubmit} isDisabled={!deviceAddress || moisture === undefined || deviceVersion === undefined}>Submit</Button>
            </Container>
        </Navbar >
    )
}

export default AddTrainingDataView;