import { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Toast,
    Skeleton,
    Container,
    StackDivider,

} from "@chakra-ui/react";

import useAxios from "../utils/useAxios";


interface Question {
    question: string;
    options: string[];
    answer: number;
    userCorrect?: boolean;
}

const TemaQuiz = () => {
    const [QuestionsList, setQuestionsList] = useState<Question[]>([]);

    const [isLoading, setIsLoading] = useState(true);
    const [startedFetching, setStartedFetching] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [isFinished, setIsFinished] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);

    const [theme, setTheme] = useState("");
    const [themesList, setThemesList] = useState<string[]>([]);

    const toast = useToast();

    const axios = useAxios();

    const fetchQuestions = () => {
        setStartedFetching(true);
        themesList.map((currentTheme, i) => {
            axios.post('/quizmaster/create-theme-quiz/',
                {
                    "theme": currentTheme
                }
            ).then((response) => {
                console.log(response.data);
                let currentQuestions = QuestionsList;
                currentQuestions.push(...response.data);
                setQuestionsList(currentQuestions);
                setIsLoading(false);
                setLoadingProgress(i);
            }).catch((error) => {
                toast({
                    title: "Error",
                    description: "Failed to fetch questions",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            });
        }
        );
    }

    const handleAnswer = (answer: number) => {
        if (answer === QuestionsList[currentQuestion].answer) {
            setScore(score + 1);
            QuestionsList[currentQuestion].userCorrect = true;
            toast({
                title: "Correct!",
                status: "success",
                duration: 2000,
                isClosable: true,
            });
        }
        else {
            QuestionsList[currentQuestion].userCorrect = false;
            toast({
                title: "Wrong!",
                status: "error",
                description: `Correct answer: ${QuestionsList[currentQuestion].options[QuestionsList[currentQuestion].answer]}`,
                duration: 4000,
                isClosable: true,
            });
        }

        setCurrentQuestion(currentQuestion + 1);

        // Check if quiz is finished
        if (currentQuestion === QuestionsList.length - 1) {
            setIsFinished(true);
        }
    }

    console.log("Theme", theme);
    console.log("ThemesList", themesList);

    const addTheme = () => {
        let currentThemes = [...themesList]; // Create a new array that copies the old one
        currentThemes.push(theme);
        console.log("Current themes", currentThemes)
        setThemesList(currentThemes); // Set the state with the new array

        setTheme(""); // Reset the input field
    }

    return (
        <Container width={"100%"} maxW={"container.lg"}>
        <Box>
            <Heading as="h1" size="2xl" textAlign="center" my={4}>
                Theme Quiz
            </Heading>
            <Text mb={4} textAlign={"center"}>
                Enter themes to start a quiz. You will get 5 questions from each theme.
            </Text>
            { !isFinished && !isLoading ? (
            <SimpleGrid columns={2} spacing={4} textAlign="center">
            <Text fontWeight={600}> Score: {score} </Text>
            <Text fontWeight={600}> Question {currentQuestion+1} of {QuestionsList?.length} </Text>
                </SimpleGrid>
            ) : null}
    
                {QuestionsList.length === 0 && !startedFetching ? (
                <Center>
                <VStack spacing={4}>
                <FormControl id="theme">
                    <FormLabel>Tema</FormLabel>
                    {themesList.map((theme, index) => (
                        <Text key={index}>{theme}</Text>
                    ))}
                    <SimpleGrid columns={2} spacing={4}>
                    <Input type="text" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTheme(e.target.value)} value={theme} />
                    <Button onClick={() => addTheme()}>Add theme</Button>
                    </SimpleGrid>
                </FormControl>
                <Button onClick={fetchQuestions} mt={4} disabled={themesList.length === 0}>
                    Generate questions
                </Button>
                </VStack>
                </Center>
            ) : null} 

            {isLoading && startedFetching ? (
                <>
                <Text>Generating questions...</Text>
                <Skeleton height="20px" /> 
                </>
            
            )
            : null}
            
            {!isLoading && currentQuestion < QuestionsList.length ? (
                <VStack spacing={4} mt={4}>
                    <Text whiteSpace={"normal"}>
                        {QuestionsList[currentQuestion].question}
                    </Text>
                    <Stack spacing={4}>
                        {QuestionsList[currentQuestion].options.map((option, index) => (
                            <Button
                                key={index}
                                onClick={() => handleAnswer(index)}
                                size="lg"
                                whiteSpace={"normal"}
                            >
                                {option}
                            </Button>
                        ))}
                    </Stack>
                </VStack>
            ) : null}

            {isFinished ? (
                <Center mt={4}>
                <VStack spacing={4}>
                <Text>Quiz finished!</Text>
                <Text>Your score: {score} of {QuestionsList?.length} </Text>
                <Button onClick={() => window.location.reload()} size="lg">Restart</Button>

                <Heading as="h2" size="lg" mt={10}>Answers</Heading>                

                <VStack spacing={4}>
                {QuestionsList.map((question, index) => (
                    <>
                    <Box key={index} textAlign="left" width={300}>   
                        <Text color={question.userCorrect ? "green" : "red"} fontWeight={600}>
                            {question.question}
                        </Text>
                        <Text>Correct answer: {question.options[question.answer]}</Text>
                    </Box>
                    <StackDivider borderColor="black" />
                    </>
                ))
                }
                </VStack>
                </VStack>

                </Center>
                
                

            ) : null}


        </Box>
        </Container>
    );
}

export default TemaQuiz;