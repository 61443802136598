import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";
import { useState, useEffect } from 'react';
import { useToast, Box } from "@chakra-ui/react";

import { IDevices } from './DeviceOverview';
import { DeviceMapMultiple } from '../components/DeviceMap';

const DeviceMapView = () => {
    const api = useAxios();
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(true);
    const [devices, setDevices] = useState<IDevices>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("frontend/devices/current-data/");
                console.log(response.data);
                setDevices(response.data);

                setIsLoading(false);
            } catch (error: any) {
                if (error.response.status === 401) {
                    // Currently, this is handled elsewhere
                    return;
                }
                setDevices([]);
                setIsLoading(false);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchData();
    }, []);

    return (
        <Navbar>
            <Box width="100%" height={{ base: 500, md: 500, lg: 700 }}>
                <DeviceMapMultiple devices={devices} />
            </Box>
        </Navbar>
    );
}

export default DeviceMapView;