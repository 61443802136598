import { useEffect, useState, useContext } from 'react';

import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
    Skeleton,
    Toast,
    Switch,
} from "@chakra-ui/react"

import { Line } from 'react-chartjs-2';

import { Field } from '../../types/fieldInterface';

import useAxios from "../../utils/useAxios";
import { Sim } from 'react-bootstrap-icons';

interface WeatherData {
    date_time: string[];
    temp: number[];
    humidity: number[];
    weather: string[];
    clouds: number[];
    wind_speed: number[];
    wind_deg: number[];
    rain: number[];
}

const hourlyDateTimeFormat: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit'
}


const dateOnlyFormat: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
}

interface TooltipItem {
    datasetIndex: number;
    index: number;
    xLabel: string;
    yLabel: number;
    label: string;
    value: string;
}

interface Data {
    datasets: {
        label: string;
    }[];
}

// NOTE: Consider making this a bit better using this: https://openweathermap.org/weather-conditions
const weatherSymbols: { [key: string]: string } = {
    'Clear': '☀️',
    'Clouds': '☁️',
    'Rain': '🌧️',
    'Drizzle': '🌧️',
    'Snow': '❄️',
    'Thunderstorm': '⛈️',
    // add more weather conditions and symbols as needed
};

const FieldWeatherWidget = ({ field }: { field: Field }) => {
    const [weatherData, setWeatherData] = useState<WeatherData | null>(null);
    const api = useAxios();

    const [aggregateDaily, setAggregateDaily] = useState<boolean>(true);

    const toast = useToast();
    const bgColor = useColorModeValue('white', 'gray.800');

    const fetchData = async () => {
        if (!field) {
            return;
        }
        try {
            const response = await api.get(`/agtech/fields/${field.id}/weather/?daily=${aggregateDaily}`);
            setWeatherData(response.data);
        } catch (error: any) {
            setWeatherData(null);
            console.log(error);
            toast({
                title: "Error fetching weather data",
                status: "error",
                description: error.code,
                duration: 9000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [field]);

    useEffect(() => {
        fetchData();
    }
    , [aggregateDaily]);

    if (!weatherData) {
        return <Skeleton height="100px" />;
    }

    const data = {
        labels: weatherData.date_time.map((timestamp, index) => {
            const date = new Date(timestamp);
            const formattedDate = aggregateDaily ? date.toLocaleDateString(undefined, dateOnlyFormat) : date.toLocaleDateString(undefined, hourlyDateTimeFormat);
            const weatherSymbol = aggregateDaily ? (weatherSymbols[weatherData.weather[index]] || '') : '';
            return `${formattedDate}${aggregateDaily ? '\n\n' + weatherSymbol : ''}`;
        }),
        datasets: [
            {
                label: 'Temperature',
                data: weatherData.temp,
                borderColor: 'rgb(255, 99, 132)',
                yAxisID: 'y-axis-1', // assign this dataset to the 'y' scale
                fill: false,
            },
            {
                type: 'bar',
                label: 'Rain [mm]',
                data: weatherData.rain,
                borderColor: 'rgb(0, 0, 240)',
                backgroundColor: 'rgba(0, 0, 240, 0.5)',
                yAxisID: 'y-axis-2', // assign this dataset to the 'y1' scale
            },
            // Add more datasets for other weather parameters as needed
        ],
    };

    const options = {
		scales: {
			yAxes:[
				{id: 'y-axis-1', type: 'linear', position: 'left',
                    scaleLabel: {
                        display: true,
                        labelString: 'Temperature (°C)' // Label for the first y-axis
                    },
                    ticks: {
                        suggestedMin: 0, suggestedMax: 10
                    },
                },
				{id: 'y-axis-2', type: 'linear', position: 'right',
                scaleLabel: {
                    display: true,
                    labelString: 'Rain (mm)' // Label for the second y-axis
                },
                ticks: {
                    min: 0, suggestedMax: 20
                },
            },
			],
		},
        tooltips: {
            callbacks: {
                label: function(tooltipItem: TooltipItem, data: Data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += tooltipItem.yLabel.toFixed(1);
                    return label;
                }
            }
        }
    }

    return (
        <Box>
            <Heading size="lg" mb="4">5 Day Weather Forecast</Heading>
            <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="aggregateDaily" mb="0">
                    Daily avg.
                </FormLabel>
                <Switch id="aggregateDaily" isChecked={aggregateDaily} onChange={() => setAggregateDaily(!aggregateDaily)} />
            </FormControl>
            <Line height={180} data={data} options={options} />
            
        </Box>
    );
};

export default FieldWeatherWidget;