import { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,

} from "@chakra-ui/react";

import { useParams } from 'react-router-dom';

import Navbar from '../../components/Navbar';
import useAxios from "../../utils/useAxios";


const GatewaysView = () => {
    // TODO: Consider adding a button to sync reboot of all devices

    const toast = useToast();
    const api = useAxios();

    const { id } = useParams<{ id: string }>();

    const [gatewayDevices, setGatewayDevices] = useState<any[]>([]);
    const [gatewayData, setGatewayData] = useState<any>({});

    const textColor = useColorModeValue("gray.800", "gray.100");
    const bgColor = useColorModeValue("gray.100", "gray.700");
    const tileColor = useColorModeValue("white", "gray.900");

    const fetchGatewayDevices = async () => {
        try {
            const response = await api.get('frontend/gateway-devices/' + id + '/');
            console.log(response.data);

            // Format timestamp
            setGatewayDevices(response.data);
        } catch (err: any) {
            toast({
                title: "Error fetching gateways",
                description: err.message,
                status: "error"
            })
        }
    }

    const fetchGatewayData = async () => {
        try {
            const response = await api.get('frontend/gateways/' + id + '/');
            console.log(response.data);

            // Format timestamp
            setGatewayData(response.data);
        } catch (err: any) {
            toast({
                title: "Error fetching gateways",
                description: err.message,
                status: "error"
            })
        }
    }

    const sendTurnOnLedCommand = async (deviceAddress: string) => {
        // TODO: Add a confirmation check after 5 seconds
        try {
            const response = await api.post('iot/send-turn-on-led-command/', {
                device_address: deviceAddress,
                led_number: 1,
                duration: 60
            }
            );
            console.log(response.data);
            toast({
                title: "Command sent. Refresh to confirm that it is executed.",
                status: "success"
            })
        } catch (err: any) {
            toast({
                title: "Error sending command",
                description: err.message,
                status: "error"
            })
        }
    }

    useEffect(() => {
        fetchGatewayDevices();
        fetchGatewayData();
    }, []);

    const sendMeshOptimizationCommand = async () => {
        try {
            const response = await api.post('iot/broadcast-power-saving-mode/', {
                gateway_address: gatewayData.gateway_address,
                power_saving_mode: 0
            }
            );
            console.log(response.data);
            toast({
                title: "Command sent.",
                status: "success"
            })
        } catch (err: any) {
            toast({
                title: "Error sending command",
                description: err.message,
                status: "error"
            })
        }
    }

    return (
        <>
            <Navbar>
                <Container maxW="container.xl" p={8}>
                    <Stack direction="row" spacing={4} mb={4}>
                        <Heading size="lg" color={textColor}>Devices for gateway</Heading>
                    </Stack>
                    <Button size="sm" colorScheme="teal" variant={"outline"} mb={4} onClick={sendMeshOptimizationCommand}>Search & optimize mesh</Button>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                        {gatewayDevices.map((device: any) => {
                            return (

                                <Box
                                    key={device.id}
                                    p={4}
                                    bg={tileColor}
                                    borderRadius="lg"
                                    boxShadow="md"
                                    color={textColor}
                                >
                                    <Heading size="md" mb={2}>{device.device_name ? device.device_name : "Unnamed Device"}</Heading>
                                    <Text mb={2}>Address: {device.device_address}</Text>
                                    <Text mt={4}>
                                        Last Seen: {new Date(device.last_seen).toLocaleString('default', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
                                    </Text>
                                    <Text mt={4}> {device.downstream_queue_size ? device.downstream_queue_size + " command(s) pending" : "All commands executed"}</Text>
                                    <Text mt={4}> {device.fw_version ? "Firmware version: " + device.fw_version : "Firmware version: Unknown"}</Text>

                                    <Button mt={4} size="sm" colorScheme="teal" onClick={() => sendTurnOnLedCommand(device.device_address)}>Turn on LED</Button>
                                </Box>
                            )
                        })}
                    </SimpleGrid>
                </Container>
            </Navbar>
        </>
    )
}

export default GatewaysView;