import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Spacer,
    Flex,
    Container,
} from "@chakra-ui/react";

import {
    CheckCircleIcon, WarningTwoIcon,
} from '@chakra-ui/icons';

import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";


interface IAlarm {
    id: number;
    is_active: boolean;
    device_is_offline: boolean;
    created_at: string;
    modified_at: string;
    sent_at: string | null;
    device: number;
    device_name?: string;
    alarm_type: string;
    trigger_value: number;
    trigger_below_or_above: boolean;
}




const AlarmsView = () => {
    const [alarms, setAlarms] = useState<IAlarm[] | null>(null);

    const axios = useAxios();

    const toast = useToast();

    const navigate = useNavigate();

    const boxColor = useColorModeValue('white', 'gray.900');

    useEffect(() => {
        axios.get('/frontend/alarms/')
            .then((response) => {
                console.log('Alarms fetched', response.data)
                setAlarms(response.data);
            })
            .catch((error) => {
                console.error('Error fetching alarms', error);
                toast({
                    title: 'Error fetching alarms',
                    description: error.message,
                    status: 'error',
                    isClosable: true,
                });
            });
    }, []);

    return (
        <Navbar>
            <Box p={4}>
                <Container>
                <Stack direction="row">
                    <Heading as="h1" size="2xl">Alarms</Heading>
                    <Spacer />
                    <Button size="lg" colorScheme="teal" bg={boxColor} variant={"outline"} onClick={() => navigate('add')}>Add Alarm</Button>
                </Stack>
                <Text mt={4} align={"center"}>
                    Add or edit alarms. When an alarm is triggered, an email is sent to the user.
                    Updated every hour.

                </Text>
                </Container>
                <Center>
                    <SimpleGrid columns={{base: 1, lg: 2, xl: 3, '2xl': 4}} spacing={4} mt={4}>
                        {alarms?.map((alarm) => (
                            <Box key={alarm.id} p={3} borderRadius="lg" boxShadow="md" bg={boxColor} maxWidth={"lg"}>
                                <Stack direction="row">
                                    <Link to={`/devices/${alarm.device}`}>
                                        <Heading as="h2" size="lg">{alarm.device_name ? alarm.device_name : `Device ${alarm.device}`}</Heading>
                                    </Link>
                                    <Spacer />
                                    <Button onClick={() => navigate(`edit/${alarm.id}`)} size="md" colorScheme="teal" variant={"outline"}>Edit</Button>
                                </Stack>
                                <SimpleGrid columns={2} spacing={2}>
                                    <Box>
                                        <Text>{alarm.alarm_type}</Text>
                                        <Text>Trigger: {alarm.trigger_below_or_above ? "<" : ">" }{alarm.trigger_value}</Text>
                                        <Text>
                                            {alarm.device_is_offline ? 'Device is offline' : 'Device is online'}
                                        </Text>
                                        <Text>
                                            Alarm: {alarm.device_is_offline ? 'Unknown' : (alarm.is_active ? 'Triggered' : 'Not triggered')}                                        </Text>
                                    </Box>
                                    <Box>
                                    <Flex alignItems="center" justifyContent="center" height="100%">
                                        {alarm.is_active ? (
                                            <WarningTwoIcon color={'red.500'} w={8} h={8} />
                                        ) : alarm.device_is_offline ? (
                                            <WarningTwoIcon color={'orange.500'} w={8} h={8} />
                                        ) : (
                                            <CheckCircleIcon color={'green.500'} w={8} h={8} />
                                        )}
                                    </Flex>
                                    </Box>
                                </SimpleGrid>
                            </Box>
                        ))}
                    </SimpleGrid>
                </Center>
            </Box>
        </Navbar>
    );
}

export default AlarmsView;