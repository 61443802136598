import Map from 'react-map-gl';
import { Marker, Popup, GeolocateControl, FullscreenControl, NavigationControl, ScaleControl, Source } from 'react-map-gl';
import { useState, useContext } from 'react';
import { Heading, VStack, Box, Text, Center, Tooltip, useColorModeValue, Skeleton } from "@chakra-ui/react";
import { IDevice } from '../views/DeviceOverview';
import { DataDump } from './DeviceTile';
import authContext from '../context/AuthContext';
import { BiDevices } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import { IData } from '../views/DeviceOverview';

// Function to calculate color gradient based on soil moisture
export function calculateMoistureColor(value: number|undefined, params: Record<string, number>): string {
    // Define Colors for low, medium and high soil moisture
    const lowMoistureColor: number[] = [255, 0, 0]; // RGB for red
    const midMoistureColor: number[] = [0, 255, 0]; // RGB for green
    const highMoistureColor: number[] = [0, 0, 255]; // RGB for blue

    if (value === undefined) {
        return `rgb(0,0,0)`;
    }

    // Calculation of the 'distance' of the moisture level from the minimum moisture, step within the range from 0 to 1
    const step = Math.min(Math.max((value - params.min) / (params.max - params.min), 0), 1);

    // Interpolate between the two colors based on the step
    const blendColors = (color1: number[], color2: number[], ratio: number): number[] => {
        return color1.map((color: number, index: number) => {
            return Math.round(color + ratio * (color2[index] - color));
        });
    }

    let color: number[];
    if (step <= 0.5) {
        color = blendColors(lowMoistureColor, midMoistureColor, step * 2);
    } else {
        color = blendColors(midMoistureColor, highMoistureColor, (step - 0.5) * 2);
    }

    return `rgb(${color[0]},${color[1]},${color[2]})`;
}

// Function to calculate color gradient based on temperature
export function calculateTemperatureColor(value: number|undefined, params: Record<string, number>): string {
    // Define Colors for cool and warm temperatures
    const coolColor: number[] = [0, 0, 255]; // RGB for blue
    const warmColor: number[] = [255, 0, 0]; // RGB for red

    if (value === undefined) {
        return `rgb(0,0,0)`;
    }

    // Calculation of the 'distance' of the temperature level from the minimum temperature, step within the range from 0 to 1
    const step = Math.min(Math.max((value - params.min) / (params.max - params.min), 0), 1);

    // Interpolate between the two colors based on the step
    const blendColors = (color1: number[], color2: number[], ratio: number): number[] => {
        return color1.map((color: number, index: number) => {
            return Math.round(color + ratio * (color2[index] - color));
        });
    }

    const color = blendColors(coolColor, warmColor, step);

    return `rgb(${color[0]},${color[1]},${color[2]})`;
}

const Gradient = () => {
    const { userSettings } = useContext(authContext);
    const min=userSettings.moisture_plot_minimum
    const max=userSettings.moisture_plot_maximum
    const gradientSteps = Array.from({ length: 101 }, (_, index) => index / 100);
    const textColor = useColorModeValue('gray.600', 'gray.400');

    return (
        <Tooltip label="You can change the gradient color range in the settings.">
            <Box display={"flex"} flexDirection="column" textColor={textColor}>
                <svg width="100%" height="20">
                    <defs>
                        <linearGradient id="Gradient" x1="0" x2="1" y1="0" y2="0">
                            {gradientSteps.map((step, index) =>
                                <stop key={`stop-${index}`} offset={`${step * 100}%`} stopColor={calculateMoistureColor(min + (max - min) * step, {min, max})} />)
                            }
                        </linearGradient>
                    </defs>
                    <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient)" />
                </svg>

                <Box display="flex" justifyContent="space-between" textColor={textColor}>
                    <Text fontSize="xs">{min}</Text>
                    <Text fontSize="xs">{(max + min) / 2}</Text>
                    <Text fontSize="xs">{max}</Text>
                </Box>
                <Text fontSize="sm" textColor={textColor} align={"center"} fontWeight={600}>Soil Moisture (% VWC)</Text>
            </Box>
        </Tooltip>
    );
};


const TemperatureGradient = () => {
    const { userSettings } = useContext(authContext);
    const min=userSettings.temperature_plot_minimum
    const max=userSettings.temperature_plot_maximum
    const gradientSteps = Array.from({ length: 101 }, (_, index) => index / 100);
    const textColor = useColorModeValue('gray.600', 'gray.400');

    return (
        <Tooltip label="You can change the gradient color range in the settings.">
            <Box display={"flex"} flexDirection="column" textColor={textColor}>
                <svg width="100%" height="20">
                    <defs>
                        <linearGradient id="Gradient" x1="0" x2="1" y1="0" y2="0">
                            {gradientSteps.map((step, index) =>
                                <stop key={`stop-${index}`} offset={`${step * 100}%`} stopColor={calculateTemperatureColor(min + (max - min) * step, {min, max})} />)
                            }
                        </linearGradient>
                    </defs>
                    <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient)" />
                </svg>

                <Box display="flex" justifyContent="space-between" textColor={textColor}>
                    <Text fontSize="xs">{min}</Text>
                    <Text fontSize="xs">{(max + min) / 2}</Text>
                    <Text fontSize="xs">{max}</Text>
                </Box>
                <Text fontSize="sm" textColor={textColor} align={"center"} fontWeight={600}>Temperature (°C)</Text>
            </Box>
        </Tooltip>
    );
}


const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const VALVE_ICON = `M440-640v-120H280v-80h400v80H520v120h-80ZM160-120v-320h80v40h120v-120h-40v-80h320v80h-40v120h120v-40h80v320h-80v-40H240v40h-80Zm80-120h480v-80H520v-200h-80v200H240v80Zm240 0Z`;

const pinStyle = {
    cursor: 'pointer',
    fill: '#d00',
    stroke: 'none'
};

function Pin({ size = 30, color = "rgb(0,0,0)" }) {
    return (
        <svg height={size} viewBox="0 0 24 24" style={{ ...pinStyle }}>
            <path d={ICON} fill={color} />
        </svg>
    );
}


const DeviceMap = ({ device }: any) => {
    const [popupInfo, setPopupInfo] = useState<IDevice | null>(null);
    const { userSettings } = useContext(authContext);
    if (!device) return (<Skeleton height="100%" width="100%" />);
    if (!device?.latitude || !device?.longitude) return (<>You have not set the location yet.</>);
    
    // Check if the device data is newer than 4 hours. If not, the color will be grey
    const lastUpdate = new Date(device.data?.timestamp);
    const now = new Date();
    const timeDiff = now.getTime() - lastUpdate.getTime();

    let color = "#a3a3a3";
    if (timeDiff < 4 * 60 * 60 * 1000) {
        color = calculateMoistureColor(device.data?.soil_moisture, {min: userSettings.moisture_plot_minimum, max: userSettings.moisture_plot_maximum});
    }

    return (
        <Map
            mapLib={import('mapbox-gl')}
            initialViewState={{
                longitude: device.longitude,
                latitude: device.latitude,
                zoom: 15
            }}
            mapStyle="mapbox://styles/mapbox/satellite-v9"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        >
            <ScaleControl />
            <Marker
                latitude={device.latitude}
                longitude={device.longitude}
                anchor={"bottom"}
                onClick={e => {
                    // If we let the click event propagates to the map, it will immediately close the popup
                    // with `closeOnClick: true`
                    e.originalEvent.stopPropagation();
                    setPopupInfo(device);
                }}
            >
                <Pin size={30} color={color} />
            </Marker>
            {popupInfo && (
                <Popup
                    anchor="top"
                    longitude={Number(popupInfo?.longitude)}
                    latitude={Number(popupInfo?.latitude)}
                    onClose={() => setPopupInfo(null)}
                >
                    <VStack align="start" spacing={1} fontSize={13} textColor={"black"} >
                        <Heading size="md">{popupInfo.device_name ? popupInfo.device_name : "Unnamed device"}</Heading>
                        <DataDump device={popupInfo} />
                    </VStack>
                </Popup>
            )}
        </Map>
    );
}

// Define the type for the calculateColor function
type CalculateColorFunction = (value: number|undefined, params: Record<string, number>) => string;

const RenderDevices = ({ devices, plotkey, color_function, color_params }: { devices: IDevice[], plotkey: keyof IData, color_function: CalculateColorFunction, color_params: Record<string, number>}) => {
    const [popupInfo, setPopupInfo] = useState<IDevice | null>(null);

    return (
        <>
            {devices.map((device) => (
                device.latitude && device.longitude && device.data[plotkey] !== undefined && (
                    <Marker
                        key={device.device_id}
                        latitude={device.latitude}
                        longitude={device.longitude}
                        anchor={"bottom"}
                        onClick={e => {
                            // If we let the click event propagates to the map, it will immediately close the popup
                            // with `closeOnClick: true`
                            e.originalEvent.stopPropagation();
                            setPopupInfo(device);
                        }}
                    >
                        {/* TODO: The pin can be based on the device type */}
                        <Pin
                            size={30}
                            color={
                                // Check if the device data is newer than 4 hours. If not, the color will be grey
                                new Date().getTime() - new Date(device.data?.timestamp).getTime() < 4 * 60 * 60 * 1000 ? 
                                color_function(parseFloat(device.data[plotkey] as string), color_params)
                                : "#a3a3a3"
                            }
                        />
                    </Marker>
                )
            ))}
            {popupInfo && (
                <Popup
                    anchor="top"
                    longitude={Number(popupInfo?.longitude)}
                    latitude={Number(popupInfo?.latitude)}
                    onClose={() => setPopupInfo(null)}
                >
                    <VStack align="start" spacing={1} fontSize={13} textColor={"black"} >
                        <Heading size="md">{popupInfo.device_name ? popupInfo.device_name : "Unnamed device"}</Heading>
                        <Link to={`/devices/${popupInfo.device_id}`}>
                            <DataDump device={popupInfo} />
                        </Link>
                    </VStack>
                </Popup>
            )}
        </>
    );
};


const DeviceMapMultiple = ({ devices }: { devices: IDevice[] }) => {
    const { userSettings } = useContext(authContext);
    // Set initial viewport to the first device with a location
    let initialViewport = {
        longitude: 0,
        latitude: 0,
        zoom: 1
    };
    if (!devices) return (<Skeleton height="100%" width="100%" />);
    let foundDeviceWithLocation = false;
    for (let device of devices) {

        if (!device.latitude || !device.longitude) continue;

        if (device?.latitude !== null && device?.longitude !== null &&
            device.latitude >= -90 && device.latitude <= 90 &&
            device.longitude >= -180 && device.longitude <= 180) {
            initialViewport = {
                longitude: device.longitude,
                latitude: device.latitude,
                zoom: 15
            };
            foundDeviceWithLocation = true;
            break;
        }
    }

    if (!foundDeviceWithLocation) return (<>You don't have any devices with a location yet.</>);

    return (
        <>
            <Map
                mapLib={import('mapbox-gl')}
                initialViewState={initialViewport}
                mapStyle="mapbox://styles/mapbox/satellite-v9"
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                style={{ width: "100%", height: "100%" }}
                terrain={{source: 'mapbox-dem', exaggeration: 1.5}}
            >
                <Source
                    id="mapbox-dem"
                    type="raster-dem"
                    url="mapbox://mapbox.mapbox-terrain-dem-v1"
                    tileSize={512}
                    maxzoom={14}
                />
                <ScaleControl />
                {!!devices &&
                <RenderDevices
                    devices={devices}
                    plotkey='soil_moisture'
                    color_function={calculateMoistureColor}
                    color_params={{min: userSettings.moisture_plot_minimum, max: userSettings.moisture_plot_maximum}}
                />}
                <GeolocateControl
                    positionOptions={{ enableHighAccuracy: true }}
                    trackUserLocation={true}
                    showUserLocation={true}
                />
                <FullscreenControl />
                <NavigationControl />
            </Map>
            <Center>
                <Box height="20px" width={{ base: "90%", md: "50%" }} mt={4}>
                    <Gradient />
                </Box>
            </Center>
        </>
    );
}

export { DeviceMap, DeviceMapMultiple, RenderDevices, Gradient, TemperatureGradient };

export type { IDevice };