import { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
    Link,
    Badge
} from "@chakra-ui/react";

import Navbar from '../../components/Navbar';
import useAxios from "../../utils/useAxios";


const AutoplantView = () => {
    // We will have three badges: "Moisture", "Battery", "Reservoir". Grey out any unknown values or if is_online is false.
    const toast = useToast();
    const api = useAxios();

    const [autoplants, setAutoplants] = useState<any[]>([]);

    const textColor = useColorModeValue("gray.800", "gray.100");
    const bgColor = useColorModeValue("gray.100", "gray.700");
    const tileColor = useColorModeValue("white", "gray.900");

    const fetchAutoplants = async () => {
        try {
            const response = await api.get('frontend/autoplants/');
            console.log(response.data);

            // Sort by connected devices
            response.data.sort((a: any, b: any) => {
                return b.number_of_devices - a.number_of_devices;
            });

            // Format timestamp
            setAutoplants(response.data);
        } catch (err: any) {
            toast({
                title: "Error fetching autoplants",
                description: err.message,
                status: "error"
            })
        }
    }

    useEffect(() => {
        fetchAutoplants();
    }, []);


    const calculateBatteryLevel = (battery_voltage: number) => {
        const battery_min = 2.2;
        const battery_max = 3.1;

        const battery = (battery_voltage - battery_min) / (battery_max - battery_min);
        return battery * 100;
    }

    return (
        <>
            <Navbar>
                <Container maxW="container.xl" mt={10}>
                    <Heading as="h1" size="xl" mb={5} color={textColor}>
                        Autoplants
                    </Heading>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                        {autoplants.map((autoplant, index) => (
                            <Box key={index} p={4} shadow="lg" borderRadius="lg" boxShadow={"md"} bg={tileColor}>
                                <Link href={`/internal/autoplants/${autoplant.id}`}>
                                    <Heading size="lg" mb={3}>{autoplant.device_name ? autoplant.device_name : "Unnamed Autoplant"}</Heading>
                                    <SimpleGrid columns={3} spacing={2}>
                                        {autoplant.is_online ? (
                                            <>
                                                <Badge p={1} colorScheme={autoplant.soil_moisture > 15 ? "green" : autoplant.soil_moisture > 10 ? "orange" : "red"}>
                                                    Moisture
                                                </Badge>
                                                <Badge p={1} colorScheme={
                                                    calculateBatteryLevel(autoplant.battery_voltage) > 40 ? "green" :
                                                        calculateBatteryLevel(autoplant.battery_voltage) > 20 ? "orange" :
                                                            "red"
                                                }>
                                                    Battery
                                                </Badge>
                                                <Badge p={1} colorScheme={
                                                    autoplant.reservoir_empty === 0 ? "green" : autoplant.reservoir_empty === 1 ? "red" : "gray"
                                                }>
                                                    Reservoir
                                                </Badge>
                                            </>
                                        ) : <Badge colorScheme="gray">Device offline</Badge>}
                                    </SimpleGrid>
                                    <Text mt={4}>
                                        Soil Moisture: {autoplant.soil_moisture?.toFixed(0)}%
                                    </Text>
                                    <Text mt={4}>
                                        Battery: {autoplant.battery_voltage && calculateBatteryLevel(autoplant.battery_voltage).toFixed(0)}% ({autoplant.battery_voltage?.toFixed(2)}V)
                                    </Text>
                                    <Text mt={4}>
                                        {autoplant.reservoir_empty === 0 ? "Reservoir OK" :
                                            autoplant.reservoir_empty === 1 ? "Reservoir is empty" :
                                                "Reservoir status unknown"}
                                    </Text>
                                    <Text mt={4}>
                                        Last Seen: {autoplant.last_seen ? (new Date(autoplant.last_seen).toLocaleString('default', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })) : "Unknown"}
                                    </Text>
                                </Link>
                            </Box>
                        ))}
                    </SimpleGrid>
                </Container>
            </Navbar>
        </>
    );
}

export default AutoplantView;
