import ReactGeoJSON from 'react-geojson';
import { useState, useEffect } from 'react';
import { Container, Box, VStack, StackDivider, useColorModeValue, Heading } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/react";
import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";

const MapSelector = () => {
    // TODO: Check API key

    const api = useAxios();
    const toast = useToast();

    const [geojson, setGeojson] = useState(JSON.parse(null));
    const [isLoading, setIsLoading] = useState(true);
    const [center, setCenter] = useState({ lat: 58.9644, lng: 5.7262658 });

    const mapStyles = [{ featureType: 'poi', stylers: [{ visibility: 'off' }] }];
    const areaStyles = { fillColor: '#ff0000', strokeColor: '#ff0000', strokeWeight: 2 };
    let mapOptions = { mapTypeId: 'satellite', searchBox: true };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("agtech/area/");
                console.log(response.data);
                setGeojson(response.data.geo_json);
                // if the geo_json is not null, then update the location to the center of the geo_json
                if (response.data.geo_json !== null) {
                    const lat = response.data.geo_json.features[0].geometry.coordinates[0][0][1];
                    const lng = response.data.geo_json.features[0].geometry.coordinates[0][0][0];
                    setCenter({ lat: lat, lng: lng });
                }
                setIsLoading(false);
            } catch {
                setGeojson(null);
                setIsLoading(false);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchData();
    }, []);

    const handleSave = async (data) => {
        console.log(JSON.stringify(data));
        setGeojson(data);
        try {
            const { data: response } = await api.put('agtech/area/', { geo_json: data });
        } catch (error) {
            console.log(error);
            toast({
                title: "Error saving data",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    }


    return (
        <Navbar>
            <Box mx={{ base: 2, sm: 4, md: 6 }}>
                <VStack
                    mt={2}
                    spacing={2}
                    align="stretch"
                    divider={
                        <StackDivider
                            borderColor={useColorModeValue('gray.200', 'gray.600')}
                        />
                    }>
                    <Heading lineHeight={1.1}
                        fontWeight={600}
                        color={useColorModeValue('gray.900', 'white')}
                        fontSize={{ base: 'xl', sm: '3xl', lg: '4xl' }}>Change your area</Heading>
                    <Box
                        width={"500"}
                        height={"500"}
                        color="gray.100"
                        outlineColor="gray.300"
                        fontSize="large"
                        fontWeight="bold">

                        {isLoading ? <p>Loading...</p> : (
                            <ReactGeoJSON
                                apiKey={process.env.REACT_APP_MAPS_API_KEY}
                                onMapInitiated={(map) => console.log('Map initiated', map)}
                                onPolygonsDrawn={(polygons) => console.log('Available polygons', polygons)}
                                scriptLibraries="geometry"
                                existingArea={geojson}
                                onSave={(data) => handleSave(data)}
                                mapStyles={mapStyles}
                                areaStyles={areaStyles}
                                zoom={12}
                                center={center}
                                mapOptions={mapOptions}
                            />
                        )}
                    </Box>
                </VStack>
            </Box>
        </Navbar>
    )
}

export default MapSelector;