import {
    Text,
    Flex,
    Heading,
    Box,
    Spacer,
    VStack,
    StackDivider,
    SimpleGrid,
    Avatar,
    Center,
    Button,
} from "@chakra-ui/react";



import { AddIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { useColorModeValue } from "@chakra-ui/react";
import useAxios from "../../utils/useAxios";
import Navbar from "../../components/Navbar";
import AddDeviceDrawer from "../../components/Legacy/AddDeviceDrawer";

import { EnvironmentStatusTile, SoilStatusTile, WateringStatusTile } from "../../components/Legacy/StatusTiles";
import { RiPlantFill } from "react-icons/ri";

import FancyPageLoadingAnymation from "../../components/FancyPageLoadingAnymation";

const AutoPlantOverview = ({ autoplant }: any) => {
    const successColor = useColorModeValue("green.500", "green.200");
    const errorColor = useColorModeValue("red.500", "red.200");
    return (
        <Center>
            <Box
                bg={useColorModeValue('white', 'gray.900')}
                boxShadow={'2xl'}
                rounded={'lg'}
                p={{ base: 3, sm: 6, md: 8 }}
                textAlign={'center'}
                width="100%"
                maxWidth={"30em"}
            >
                {autoplant.parameter_status.image_url ? (

                    <Avatar
                        size={'xl'}
                        src={
                            autoplant.parameter_status.image_url
                        }
                        mb={4}
                        pos={'relative'}
                    />
                ) : (
                    <Avatar
                        size={'xl'}
                        icon={<RiPlantFill />}
                        mb={4}
                        pos={'relative'}
                    />
                )}

                <Heading fontSize={'2xl'} fontFamily={'body'}>
                    {autoplant.parameter_status?.plant_type}
                </Heading>
                <Text fontWeight={600} color={'gray.500'} mb={4}>
                    {autoplant.device_data?.device_name}
                </Text>
                <Text
                    textAlign={'center'}
                    color={useColorModeValue('gray.700', 'gray.400')}
                    px={3}
                >
                    Click a card to see more details
                </Text>

                <SimpleGrid>
                    <SoilStatusTile
                        autoplant={autoplant}
                    />
                    <EnvironmentStatusTile
                        autoplant={autoplant}
                    />
                    <WateringStatusTile
                        autoplant={autoplant}
                    />
                </SimpleGrid>
            </Box>
        </Center>
    );
}





const Dashboard = () => {
    const [autoplants, setAutoplants] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const api = useAxios();

    const toast = useToast();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("frontend/dashboard/");
                console.log(response.data);
                setAutoplants(response.data);
                setIsLoading(false);
            } catch (error: any) {
                if (error.response.status === 401) {
                    // Currently, this is handled elsewhere
                    return;
                }
                setAutoplants([]);
                setIsLoading(false);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchData();
    }, []);

    return (
        <Navbar>
            <Box mx={{ base: 2, sm: 4, md: 6 }}>
                <VStack
                    mt={2}
                    spacing={2}
                    align="stretch"
                    divider={
                        <StackDivider
                            borderColor={useColorModeValue('gray.200', 'gray.600')}
                        />
                    }>

                    <Flex>
                        <Center as={'header'}>
                            <Heading
                                lineHeight={1.1}
                                fontWeight={600}
                                color={useColorModeValue('gray.900', 'white')}
                                fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}>
                                Plants
                            </Heading>
                        </Center>
                        <Spacer />
                        <Button leftIcon={<AddIcon />} onClick={onOpen}>
                            Add device
                        </Button>

                        <AddDeviceDrawer isOpen={isOpen} onClose={onClose} />

                    </Flex>
                    {isLoading ? (
                        <FancyPageLoadingAnymation />
                    ) : (
                        <SimpleGrid
                            minChildWidth={{ base: '100%', md: '25em' }}
                            spacing={{ base: 2, md: 3 }}
                            py={3}>
                            {autoplants?.map((autoplant) => (
                                <AutoPlantOverview
                                    key={autoplant['id']}
                                    autoplant={autoplant}
                                />
                            ))}
                        </SimpleGrid>
                    )}
                </VStack>
            </Box>
        </Navbar>
    );
}

export default Dashboard;
