import {
    Heading,
    Box,
    VStack,
    StackDivider,
    SimpleGrid,
    Icon,
    Grid,
    GridItem,
    useDisclosure,
    Center,
    Text,
    Button,
    ButtonGroup,
    Input,
} from "@chakra-ui/react";

import { useColorModeValue } from "@chakra-ui/react";

import { useState, useEffect, useRef } from "react";

import { GiSeedling, GiWateringCan, GiSettingsKnobs } from "react-icons/gi";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { FiSettings } from "react-icons/fi";
import { GoTextSize } from "react-icons/go";

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'

import { css } from "@emotion/react";

import SelectPlantSpecies from "./SelectPlantSpecies";

import {
    TemperatureParameter,
    HumidityParameter,
    LightParameter,
    LastSeenParameter,
    MoistureParameter,
    NutrientsParameter,
    ActiveParameter,
    LastWateredParameter,
    WateredAmountParameter,
    TotalWateredParameter,
    CurrentSpeciesParameter,
    NicknameParameter,

} from "./ParameterStatus";

import useAxios from "../../utils/useAxios";

export const PlantSpeciesConfigTile = ({ autoplant }: { autoplant: any }) => {
    const iconColor = useColorModeValue("gray.600", "gray.400");
    const [selectedPlantSpecies, setSelectedPlantSpecies] = useState(
        { label: autoplant.parameter_status.plant_type, value: autoplant.device_data.device_plant_type }
    );

    const [buttonActive, setButtonActive] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef(null)
    const api = useAxios();

    useEffect(() => {
        if (selectedPlantSpecies.value !== autoplant.device_data.device_plant_type) {
            setButtonActive(true);
        } else {
            setButtonActive(false);
        }
    }, [selectedPlantSpecies]);

    const handlePlantSpeciesChange = () => {
        api.patch(`/users/autoplant-devices/autoplant-devices/${autoplant.device_data.id}/`, {
            device_plant_type: selectedPlantSpecies.value
        }).then((response) => {
            console.log(response);
            setButtonActive(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    // Handle change of autoplant
    useEffect(() => {
        setSelectedPlantSpecies({ label: autoplant.parameter_status.plant_type, value: autoplant.device_data.device_plant_type });
    }, [autoplant]);

    return (
        <>
            <Box
                p={3}
                shadow="md"
                borderWidth="1px"
                borderRadius="lg"
                bg={useColorModeValue("white", "gray.800")}
            >
                <VStack
                    divider={
                        <StackDivider

                            borderColor={useColorModeValue('gray.200', 'gray.600')}
                        />}
                >
                    <Grid templateColumns="repeat(3, 1fr)" width={"100%"} my="-1">
                        <GridItem>
                            <Icon boxSize="10" color={iconColor} as={GiSeedling} />
                        </GridItem>
                        <GridItem>
                            <Heading size="md" pt="2">Species</Heading>
                        </GridItem>
                    </Grid>
                    <Box width={"100%"} as="form">
                        <SelectPlantSpecies selectedSpecies={selectedPlantSpecies} setSelectedSpecies={setSelectedPlantSpecies} />
                        <Button
                            colorScheme="green"
                            variant="outline"
                            width={"100%"}
                            mt={2}
                            isDisabled={!buttonActive}
                            onClick={onOpen}
                        >Change Species</Button>
                    </Box>
                </VStack>
            </Box>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Change plant species
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to change the plant species? This will reset all the parameters.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='green' onClick={
                                () => {
                                    handlePlantSpeciesChange();
                                    onClose();
                                }
                            }
                                ml={3}>
                                Change
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export const WateringConfigTile = ({ autoplant }: { autoplant: any }) => {
    const iconColor = useColorModeValue("gray.600", "gray.400");
    const [wateringActiveState, setWateringActiveState] = useState(autoplant.device_data.regulator_is_enabled);

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef(null)

    useEffect(() => {
        setWateringActiveState(autoplant.device_data.regulator_is_enabled);
    }, [autoplant]);


    const api = useAxios();

    const handleWateringActiveChange = () => {
        api.post(`/users/watering/${autoplant.device_data.id}/`, {
            state: !wateringActiveState
        }).then((response) => {
            console.log(response);
            setWateringActiveState(!wateringActiveState);
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <Box
                p={3}
                shadow="md"
                borderWidth="1px"
                borderRadius="lg"
                bg={useColorModeValue("white", "gray.800")}
            >
                <VStack
                    divider={
                        <StackDivider
                            borderColor={useColorModeValue('gray.200', 'gray.600')}
                        />}
                >
                    <Grid templateColumns="repeat(3, 1fr)" width={"100%"} my="-1">
                        <GridItem>
                            <Icon boxSize="10" color={iconColor} as={GiWateringCan} />
                        </GridItem>
                        <GridItem>
                            <Heading size="md" pt="2">Watering</Heading>
                        </GridItem>
                    </Grid>
                    <Box width={"100%"} as="form">
                        <ButtonGroup width="100%" isAttached variant="outline">
                            <Button
                                width="50%"
                                colorScheme="green"
                                isActive={!wateringActiveState}
                                css={(
                                    !wateringActiveState ? css`&:hover {cursor: not-allowed;}` : ""
                                )}
                                onClick={wateringActiveState ? onOpen : () => { }}
                            >
                                {
                                    wateringActiveState ? "Disable" : "Disabled"
                                }
                            </Button>
                            <Button
                                width="50%"
                                colorScheme="green"
                                isActive={wateringActiveState}
                                css={(
                                    wateringActiveState ? css`&:hover {cursor: not-allowed;}` : ""
                                )}
                                onClick={wateringActiveState ? () => { } : onOpen}
                            >{
                                    wateringActiveState ? "Activated" : "Activate"
                                }
                            </Button>
                        </ButtonGroup>
                    </Box>
                </VStack>
            </Box>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Turn off watering
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {wateringActiveState ?
                                "Are you sure you want to turn off the watering? This will stop the watering of the plant."
                                : "Are you sure you want to turn on the watering?"
                            }
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='green' onClick={
                                () => {
                                    handleWateringActiveChange();
                                    onClose();
                                }
                            }
                                ml={3}>
                                {wateringActiveState ? "Turn off" : "Turn on"}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};


export const NicknameconfigTile = ({ autoplant }: { autoplant: any }) => {

    const [nickname, setNickname] = useState(autoplant.device_data.device_name);
    const [changeButtonActive, setChangeButtonActive] = useState(false);
    const iconColor = useColorModeValue("gray.600", "gray.400");

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef(null)

    const api = useAxios();

    useEffect(() => {
        setNickname(autoplant.device_data.device_name);
    }, [autoplant]);

    useEffect(() => {
        if (nickname === autoplant.device_data.device_name) {
            setChangeButtonActive(false);
        } else {
            setChangeButtonActive(true);
        }
    }, [nickname]);

    const handleNicknameChange = () => {
        api.patch(`/users/autoplant-devices/autoplant-devices/${autoplant.device_data.id}/`, {
            device_name: nickname
        }).then((response) => {
            setChangeButtonActive(false);
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <Box
                p={3}
                shadow="md"
                borderWidth="1px"
                borderRadius="lg"
                bg={useColorModeValue("white", "gray.800")}
            >
                <VStack
                    divider={
                        <StackDivider

                            borderColor={useColorModeValue('gray.200', 'gray.600')}
                        />}
                >
                    <Grid templateColumns="repeat(3, 1fr)" width={"100%"} my="-1">
                        <GridItem>
                            <Icon boxSize="10" color={iconColor} as={GoTextSize} />
                        </GridItem>
                        <GridItem>
                            <Heading size="md" pt="2">Nickname</Heading>
                        </GridItem>
                    </Grid>
                    <Box width={"100%"} as="form">
                        <Input
                            placeholder="Nickname"
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                        />
                        <Button
                            width="100%"
                            colorScheme="green" mt={"2"}
                            variant="outline"
                            onClick={onOpen}
                            isDisabled={!changeButtonActive}
                        >
                            Change
                        </Button>
                    </Box>
                </VStack>
            </Box>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Turn off watering
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to change the nickname of the plant?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='green' onClick={
                                () => {
                                    handleNicknameChange();
                                    onClose();
                                }
                            }
                                ml={3}>
                                Change
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

const ConfigurePlantTile = ({ autoplant }: { autoplant: any }) => {
    const iconColor = useColorModeValue("gray.600", "gray.400");
    return (
        <Center>
            <Box
                bg={useColorModeValue('white', 'gray.900')}
                boxShadow={'2xl'}
                rounded={'lg'}
                p={{ base: 3, sm: 6, md: 8 }}
                textAlign={'center'}
                width="100%"
                maxWidth={"30em"}
            >
                <Icon boxSize="50" color={iconColor} as={GiSettingsKnobs} />

                <Heading fontSize={'2xl'} fontFamily={'body'}>
                    Configure plant
                </Heading>

                <Text
                    textAlign={'center'}
                    color={useColorModeValue('gray.700', 'gray.400')}
                    px={3}
                    mt={4}
                >
                    Here you can change the plant's settings
                </Text>

                <SimpleGrid>
                    <WateringConfigTile autoplant={autoplant} />
                    <PlantSpeciesConfigTile autoplant={autoplant} />
                    <NicknameconfigTile autoplant={autoplant} />
                </SimpleGrid>
            </Box>
        </Center >
    );
};

export default ConfigurePlantTile;