import { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
    Link,
} from "@chakra-ui/react";

import Navbar from '../../components/Navbar';
import useAxios from "../../utils/useAxios";


const GatewaysView = () => {
    const toast = useToast();
    const api = useAxios();

    const [gateways, setGateways] = useState<any[]>([]);

    const textColor = useColorModeValue("gray.800", "gray.100");
    const bgColor = useColorModeValue("gray.100", "gray.700");
    const tileColor = useColorModeValue("white", "gray.900");

    const fetchGateways = async () => {
        try {
            const response = await api.get('frontend/gateways/');
            console.log(response.data);

            // Sort by connected devices
            response.data.sort((a: any, b: any) => {
                return b.number_of_devices - a.number_of_devices;
            });

            // Format timestamp
            setGateways(response.data);
        } catch (err: any) {
            toast({
                title: "Error fetching gateways",
                description: err.message,
                status: "error"
            })
        }
    }

    useEffect(() => {
        fetchGateways();
    }, []);

    const calculateBatteryLevel = (voltage: number) => {
        return Math.round((voltage - 3230) / 920 * 100);
    }


    return (
        <>
            <Navbar>
                <Container maxW="container.xl" mt={10}>
                    <Heading as="h1" size="xl" mb={5} color={textColor}>
                        Gateways
                    </Heading>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                        {gateways.map((gateway, index) => (
                            <Box key={index} p={4} shadow="lg" borderRadius="lg" boxShadow={"md"} bg={tileColor}>
                                <Link href={"/internal/gateway-devices/" + gateway.id + "/"}>
                                    <Heading size="md" mb={2}>{gateway.gateway_name ? gateway.gateway_name : "Unnamed Gateway"}</Heading>
                                    <Text mt={4}>Address: {gateway.gateway_address}</Text>
                                    <Text mt={4}>
                                        Last Seen: {new Date(gateway.last_seen).toLocaleString('default', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
                                    </Text>
                                    <Text mt={4}> Connected devices: {gateway.number_of_devices}</Text>
                                    <Text mt={4}> Throughoutput: {gateway.number_of_messages_last_hour} messages/h</Text>
                                    <Text mt={4}> Battery: 
                                        {gateway.gateway_battery_voltage ? 
                                            (gateway.gateway_battery_voltage > 4300 ? 
                                                " Charging" 
                                                : " " + calculateBatteryLevel(gateway.gateway_battery_voltage).toFixed(0) + " %")
                                            : " Unknown"}
                                    </Text>
                                </Link>
                            </Box>
                        ))}
                    </SimpleGrid>
                </Container>
            </Navbar >
        </>
    )
}

export default GatewaysView;