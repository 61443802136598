import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";
import { useState, useEffect } from 'react';
import { useToast } from "@chakra-ui/react";
import { Container, Box, VStack, StackDivider, useColorModeValue, Heading, Skeleton, Link } from "@chakra-ui/react";

interface IDraginoDevice {
    id: number;
    created_at: string;  // Can be Date if directly working with Date objects
    last_seen: string;   // Can be Date if directly working with Date objects
    device_address: string;  // ? denotes that this field is optional
    device_name?: string;
    device_description?: string;
}

const DraginoSelect = () => {
    const api = useAxios();
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(true);
    const [DraginoDevices, setDraginoDevices] = useState<IDraginoDevice[]>([]);

    const box_color = useColorModeValue('white', 'gray.800');
    const link_color = useColorModeValue('teal.600', 'teal.200');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("interface/get-dragino-devices/");
                console.log(response.data);
                setDraginoDevices(response.data);

                setIsLoading(false);
            } catch {
                setDraginoDevices([]);
                setIsLoading(false);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchData();
    }, []);

    return (
        <Navbar>
            <Box mx={{ base: 2, sm: 4, md: 6 }} p={5} bg={useColorModeValue('white', 'gray.800')} boxShadow="lg" borderRadius="md">
                <VStack
                    mt={2}
                    spacing={4}
                    align="stretch"
                    divider={<StackDivider borderColor={useColorModeValue('gray.200', 'gray.600')} />}
                >
                    <Heading lineHeight={1.1} color={useColorModeValue('teal.500', 'teal.300')} fontSize={{ base: 'xl', sm: '2xl' }}>
                        Select a device
                    </Heading>
                    <Box
                        p={2}
                        width="100%"
                        boxShadow="base"
                        borderRadius="md"
                        bg={useColorModeValue('gray.100', 'gray.700')}
                    >
                        {isLoading ? (
                            <Skeleton height="20px" />
                        ) : (
                            <>
                                {DraginoDevices.map((device) => (
                                    <Box key={device.id} p={2} mb={2} bg={box_color} boxShadow="md" borderRadius="md">
                                        <Link href={`/dragino/${device.id}`} color={link_color}>
                                            Name: {device.device_name}. Address: {device.device_address}
                                        </Link>
                                    </Box>
                                ))}
                            </>
                        )}
                    </Box>
                </VStack>
            </Box>
        </Navbar>
    )
}

export default DraginoSelect