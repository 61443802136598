import { useState, useEffect } from 'react';
import { useToast } from "@chakra-ui/react";
import { Container, Box, VStack, StackDivider, useColorModeValue, Heading, Text, Radio, RadioGroup, Skeleton, SimpleGrid, Select } from "@chakra-ui/react";
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { IDevice } from '../views/DeviceOverview';
import useAxios from "../utils/useAxios";

const fullDateTimeFormat: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
}

const hourlyDateTimeFormat: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit'
}


const dateOnlyFormat: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
}




interface IData {
    label: string;
    y_axis: string;
    timestamps: string[];
    data?: Array<number|null>;
}

interface IPlottableDeviceData {
    soil_moisture?: Array<number|null>;
    soil_temperature?: Array<number|null>;
    soil_conductivity?: Array<number|null>;
    temperature?: Array<number|null>;
    humidity?: Array<number|null>;
    lux?: Array<number|null>;
    battery_voltage?: Array<number|null>;
    battery?: Array<number|null>;
    timestamp: Array<string>;  // Can be Date if directly working with Date objects
    formatted_timestamp: Array<string>;
}


interface IPlotSettings {
    [key: string]: { min?: number; max?: number; } | undefined;
    soil_moisture?: { min?: number; max?: number; };
    soil_temperature?: { min?: number; max?: number; };
    soil_conductivity?: { min?: number; max?: number; };
    temperature?: { min?: number; max?: number; };
    humidity?: { min?: number; max?: number; };
    lux?: { min?: number; max?: number; };
    battery_voltage?: { min?: number; max?: number; };
    battery?: { min?: number; max?: number; };
    // Add other settings here
}
  
interface IDataGraphProps {
data: IData | undefined;
chartDataIsLoading: boolean;
plotSettings?: IPlotSettings;
}


const plotSettings: IPlotSettings = {
    soil_moisture: { min: 20, max: 40 },
    humidity: { min: 0, max: 100 },
};

const DataGraph = ({ data, chartDataIsLoading, plotSettings }: { data: IData | undefined, chartDataIsLoading: boolean, plotSettings: IPlotSettings }) => {

    console.log(data);
    console.log(chartDataIsLoading);

    if (!data || chartDataIsLoading) {
        return (
            <Skeleton height="300px" width="100%" />
        );
    }

    console.log(plotSettings[data.label]);
    console.log(data);

    return (

        <Line
            height={180}
            data={{
                labels: data.timestamps,
                datasets: [
                    {
                        label: data.label.replace(/_/g, ' '), // making label from id
                        data: data.data,
                        fill: data.label === 'soil_moisture',
                        backgroundColor: 'rgb(75, 192, 192, 0.1)',
                        borderColor: 'rgba(75, 192, 192, 0.99)',
                        pointRadius: 0,
                    },
                ],
                
            }}
            options={{
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                scales: {
                  yAxes: [{
                    id: 'y',
                    type: 'linear',
                    position: 'left',
                    ticks: {
                      suggestedMin: plotSettings?.[data.label]?.min,
                      suggestedMax: plotSettings?.[data.label]?.max,
                    },
                  }],
                },
              }}
        />
    )
}

const DevicePlot = () => {
    const api = useAxios();
    const toast = useToast();

    const { id } = useParams<{ id: string }>();

    const [days, setDays] = useState<number>(7);  // Default to 7 days

    const [chartDataIsLoading, setChartDataIsLoading] = useState(true);
    const [chartData, setChartData] = useState<IPlottableDeviceData | null>(null);
    const [selectedChart, setSelectedChart] = useState<IData | undefined>(undefined);
    const [selectedChartId, setSelectedChartId] = useState<string>("soil_moisture");

    const fetchChartData = async () => {
        let dateFormat: Intl.DateTimeFormatOptions;
        try {
            let aggregate = "none";
            if (days > 30) {
                aggregate = "date";
                dateFormat = dateOnlyFormat;
            }
            else if (days > 1) {
                aggregate = "hour";
                dateFormat = hourlyDateTimeFormat;
            }
            else {
                aggregate = "none";
                dateFormat = fullDateTimeFormat;
            }
            let response = await api.get(
                "frontend/devices/plottable-data/" + id + "/",
                {
                    params: {
                        days: days,
                        aggregate: aggregate
                    }
                }
            );

            console.log(response.data.timestamp);

            response.data.formatted_timestamp = response.data.timestamp?.map(
                (timestamp: string) => new Date(timestamp).toLocaleDateString(
                    'nb-NO', dateFormat
                )
            );

            // Check whether soil_moisture exists in the response
            // If it doesn't, set the first key as the selectedChartId
            if (!Object.keys(response.data).includes(selectedChartId)) {
                setSelectedChartId(Object.keys(response.data)[0]);
            }

            console.log(response.data);
            setChartData(response.data);

            setChartDataIsLoading(false);
        } catch {
            setChartData(null);
            setChartDataIsLoading(false);
            // Toast
            toast({
                title: "Error fetching data",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchChartData();
    }, []);

    // Fetch new plot data when days changes
    useEffect(() => {
        setChartDataIsLoading(true);
        fetchChartData();
    }, [days]);

    useEffect(() => {
        if (chartData) {
            const selectedData = chartData[selectedChartId as keyof IPlottableDeviceData];

            if (Array.isArray(selectedData) && (selectedData as Array<any>).every(item => typeof item === 'number' || item === null)) {
                setSelectedChart({
                    label: selectedChartId,
                    y_axis: selectedChartId,
                    timestamps: chartData.formatted_timestamp,
                    data: selectedData as Array<number|null>,
                });
            }
        }
    }, [chartData, selectedChartId]);

    const heading_color = useColorModeValue('black', 'gray.200');

    return (
        <Box>
            <Heading as="h2" size="lg" mb={4} color={heading_color}>
                Chart
            </Heading>
            <SimpleGrid minChildWidth="300px" spacing="20px">
                <RadioGroup
                    onChange={(value: string) => setDays(Number(value))}
                    value={days.toString()}
                >
                    <Radio mr={3} value={"1"}>1 day</Radio>
                    <Radio mr={3} value={"7"}>7 days</Radio>
                    <Radio mr={3} value={"30"}>30 days</Radio>
                    <Radio mr={3} value={"90"}>90 days</Radio>
                    <Radio value={"365"}>365 days</Radio>
                </RadioGroup>
                <Select
                    placeholder='Select data to plot'
                    value={selectedChartId}
                    onChange={(event) => setSelectedChartId(event.target.value)}
                >
                    {chartData && Object.keys(chartData).map((key) => {
                        const chartDataKey = key as keyof IPlottableDeviceData;
                        if (
                            chartDataKey !== 'timestamp' &&
                            chartDataKey !== 'formatted_timestamp' &&
                            Array.isArray(chartData[chartDataKey]) &&
                            (chartData[chartDataKey] as Array<any>).every(item => typeof item === 'number' || item === null)
                        ) {
                            return (
                                <option value={chartDataKey} key={chartDataKey}>{chartDataKey.replace(/_/g, ' ')}</option>
                            );
                        }
                    })}
                </Select>
            </SimpleGrid>
            <DataGraph data={selectedChart} chartDataIsLoading={chartDataIsLoading} plotSettings={plotSettings} />
        </Box>
    )
}

export default DevicePlot;