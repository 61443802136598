import {
    Box,
    Heading,
    useToast,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
    Skeleton,
    Toast,
    Button,
    Collapse,
    useDisclosure,
} from "@chakra-ui/react"

import { Field } from '../../types/fieldInterface';

import useAxios from "../../utils/useAxios";

export default function MoistureLimitsTab({ field, setField }: { field: Field, setField: React.Dispatch<React.SetStateAction<Field>> }) {
    const toast = useToast();
    const { isOpen, onToggle } = useDisclosure();
    const api = useAxios();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: keyof Field) => {
        const value = parseFloat(e.target.value);
        setField(prev => prev ? { ...prev, [key]: value } : prev);
    };

    const getHeightPercentage = (value: number) => {
        return `${value}%`;
    };

    const getSuggested = () => {
        console.log(field);
        if (!field?.soil_texture_sand
            || !field?.soil_texture_clay
            || !field?.soil_texture_silt
            || !field?.soil_texture_organic_matter
            || !field?.crop_type
        ) {
            toast({
                title: "Fill in soil texture and crop type first",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        api.post(`agtech/get-moisture-limits/`, {
            soil_texture_sand: field.soil_texture_sand,
            soil_texture_clay: field.soil_texture_clay,
            soil_texture_silt: field.soil_texture_silt,
            soil_texture_organic_matter: field.soil_texture_organic_matter,
            crop_type: field.crop_type,
        }).then((response) => {
            setField(prev => prev ? { ...prev, ...response.data } : prev);
            toast({
                title: "Suggested limits set",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }
        ).catch((error) => {
            toast({
                title: "Error setting suggested limits",
                status: "error",
                description: error.response.data,
                duration: 9000,
                isClosable: true,
            });
        });
    }

    return (
        <Container maxW="container.xl">
            <Heading size="lg" mb={4}>Moisture Limits</Heading>
            <Text mb={4}>Set the moisture limits for the field. "Get suggested" estimates limits based on soil texture and crop type.</Text>
            <Button onClick={onToggle} mb={4}>
                {isOpen ? "Hide Explanation" : "View Explanation"}
            </Button>
            <Button mb={4} ml={2} colorScheme="teal" onClick={getSuggested}>
                Get Suggested
            </Button>
            <Collapse in={isOpen} animateOpacity>
                <Box p={4} mb={4} borderWidth="1px" borderRadius="lg">
                    <Text mb={2}><strong>Permanent Wilting Point (%):</strong></Text>
                    <Text mb={4}>
                        This is the moisture level at which plants wilt and do not recover even if water is added. It represents the lower limit of available water for plants.
                    </Text>
                    <Text mb={2}><strong>Moisture Limit Damage (%):</strong></Text>
                    <Text mb={4}>
                        This is the moisture level at which plants start to experience damage due to insufficient water. It is a critical threshold that should be avoided to prevent harm to the plants.
                    </Text>
                    <Text mb={2}><strong>Moisture Limit Deficit (%):</strong></Text>
                    <Text mb={4}>
                        This represents the deficit in moisture limit, indicating how much the current moisture level is below the optimal or required level. It helps in understanding the water needs of the field.
                    </Text>
                    <Text mb={2}><strong>Field Capacity (%):</strong></Text>
                    <Text mb={4}>
                        This is the maximum amount of moisture the field can hold after excess water has drained away. It represents the upper limit of available water for plants.
                    </Text>
                </Box>
            </Collapse>
            <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4}>
                <Box>
                    <VStack spacing={4} align="stretch">
                        <Box>
                            <FormLabel>Permanent Wilting Point (%)</FormLabel>
                            <Input
                                type="number"
                                placeholder="Permanent Wilting Point"
                                value={field?.moisture_limit_critical || ''}
                                onChange={(e) => handleInputChange(e, 'moisture_limit_critical')}
                            />
                        </Box>
                        <Box>
                            <FormLabel>Moisture Limit Damage (%)</FormLabel>
                            <Input
                                type="number"
                                placeholder="Moisture Limit Damage"
                                value={field?.moisture_limit_damage || ''}
                                onChange={(e) => handleInputChange(e, 'moisture_limit_damage')}
                            />
                        </Box>
                        <Box>
                            <FormLabel>Moisture Limit Deficit (%)</FormLabel>
                            <Input
                                type="number"
                                placeholder="Moisture Limit Deficit"
                                value={field?.moisture_limit_deficit || ''}
                                onChange={(e) => handleInputChange(e, 'moisture_limit_deficit')}
                            />
                        </Box>
                        <Box>
                            <FormLabel>Field Capacity (%)</FormLabel>
                            <Input
                                type="number"
                                placeholder="Field Capacity"
                                value={field?.moisture_limit_field_capacity || ''}
                                onChange={(e) => handleInputChange(e, 'moisture_limit_field_capacity')}
                            />
                        </Box>
                    </VStack>
                </Box>
                <Box>
                    <Heading size="md" mb={4}>Visualization</Heading>
                    { field?.moisture_limit_critical && field?.moisture_limit_damage && field?.moisture_limit_deficit && field?.moisture_limit_field_capacity ? (                    
                    <Box height="300" ml="5" width="150px" position="relative">
                        <Box position="absolute" bottom="0" height={getHeightPercentage(field?.moisture_limit_critical)} width="50px" bg="black" />
                        <Text position="absolute" left={"50px"} bottom={`calc(${getHeightPercentage(field?.moisture_limit_critical)} - 10px)`} width="100%" textAlign="left" fontSize="sm">{`--  ${field?.moisture_limit_critical} % - PWP`}</Text>
                        <Box position="absolute" bottom={getHeightPercentage(field?.moisture_limit_critical)} height={getHeightPercentage(field.moisture_limit_damage)} width="50px" bg="red" />
                        <Text position="absolute" left={"50px"} bottom={`calc(${getHeightPercentage(field?.moisture_limit_damage)} - 10px)`} width="100%" textAlign="left" fontSize="sm">{`--  ${field?.moisture_limit_damage} % - Damage`}</Text>
                        <Box position="absolute" bottom={getHeightPercentage(field?.moisture_limit_damage)} height={getHeightPercentage(field.moisture_limit_deficit)} width="50px" bg="orange" />
                        <Text position="absolute" left={"50px"} bottom={`calc(${getHeightPercentage(field?.moisture_limit_deficit)} - 10px)`} width="100%" textAlign="left" fontSize="sm">{`--  ${field?.moisture_limit_deficit} % - Deficit`}</Text>
                        <Box position="absolute" bottom={getHeightPercentage(field?.moisture_limit_deficit)} height={getHeightPercentage(field.moisture_limit_field_capacity)} width="50px" bg="green.400" />
                        <Text position="absolute" left={"50px"} bottom={`calc(${getHeightPercentage(field?.moisture_limit_field_capacity)} - 10px)`} width="100%" textAlign="left" fontSize="sm">{`--  ${field?.moisture_limit_field_capacity} % - FC`}</Text>
                        <Box position="absolute" bottom={getHeightPercentage(field?.moisture_limit_field_capacity)} height={getHeightPercentage(100-field.moisture_limit_field_capacity)} width="50px" bg="blue.600" />
                    </Box>
                    ) : (
                        <Text mb={4}>Please fill in all the fields to see the visualization</Text>    
                    )}
                </Box>
            </SimpleGrid>
        </Container>
    );
}