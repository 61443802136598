import {
    Box,
    Button,
    Stack,
    FormLabel,
    Input,
} from "@chakra-ui/react";

import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useColorModeValue,
} from '@chakra-ui/react'

import { useToast } from "@chakra-ui/react";

import { useState } from "react";
import useAxios from "../../utils/useAxios";

import SelectPlantSpecies from "./SelectPlantSpecies";

const AddDeviceDrawer = ({ isOpen, onClose }: any) => {

    const [species, setSpecies] = useState<any>(null);
    const [deviceName, setDeviceName] = useState("");
    const [deviceId, setDeviceId] = useState("");

    const toast = useToast();

    const api = useAxios();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        console.log(deviceName, deviceId, species);
        await api.post("users/autoplant-devices/autoplant-devices/", {
            device_plant_type: species.value,
            device_name: deviceName,
            device_id: deviceId,
        }).then((response) => {
            toast({
                title: "Device added successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            setDeviceId("");
            setDeviceName("");
            onClose();
        }).catch((error) => {
            toast({
                title: "Error adding device",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            onClose();
        })
    };

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent bg={useColorModeValue("white", "gray.900")}>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth='1px'>Add a new device</DrawerHeader>

                <DrawerBody>
                    <Stack spacing="24px">
                        <Box>
                            <FormLabel htmlFor="device_name">Device nickname</FormLabel>
                            <Input
                                required
                                value={deviceName}
                                onChange={(e) => setDeviceName(e.target.value)}
                                name="device_name"
                                id="device_name"
                                placeholder="My device" />
                        </Box>
                        <Box>
                            <FormLabel htmlFor="device_id">Device ID</FormLabel>
                            <Input
                                required
                                value={deviceId}
                                onChange={(e) => setDeviceId(e.target.value)}
                                id="device_id"
                                placeholder="xx:xx:xx:xx:xx"
                            />
                        </Box>
                        <Box>
                            <FormLabel htmlFor="device_type">Plant species</FormLabel>
                            <SelectPlantSpecies id="device_type" selectedSpecies={species} setSelectedSpecies={setSpecies} />
                        </Box>
                        <Box mt={4}>
                            <Button variant="outline" mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit} colorScheme="green">Save</Button>
                        </Box>
                    </Stack>
                </DrawerBody>

                <DrawerFooter>

                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default AddDeviceDrawer;