import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    VStack,
    useColorModeValue,
    Box,
    Flex,
    Text,
    Spacer,
    SimpleGrid,
    Center,
    HStack,
    StackDivider,
} from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom';

import {
    TemperatureParameter,
    HumidityParameter,
    LightParameter,
    LastSeenParameter,
    MoistureParameter,
    NutrientsParameter,
    ActiveParameter,
    LastWateredParameter,
    WateredAmountParameter,
    TotalWateredParameter,
    BatteryStatusParameter,
} from "./ParameterStatus";

export const NotImplementedModal = ({ isOpen, onClose }: any) => {

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg={useColorModeValue("white", "gray.900")}>
                <ModalHeader>Not implemented</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    This feature is not implemented yet.
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export const PlantModal = ({ isOpen, onClose, autoplant }: any) => {
    // TODO: Include battery in this one
    const navigate = useNavigate();
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg={useColorModeValue("white", "gray.900")}  >
                <ModalHeader>Plant</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box
                        textAlign={'center'}
                        width="100%"
                    >
                        <VStack
                            fontSize={"md"}
                            align="stretch"
                            divider={
                                <StackDivider
                                    borderColor={useColorModeValue('gray.200', 'gray.600')}
                                />
                            }>
                            <Box width="100%">
                                <HStack>
                                    <Box width={80}>
                                        <LastSeenParameter autoplant={autoplant} />
                                    </Box>
                                    <Box width={"100%"}>
                                        <Text>Device connection status</Text>
                                    </Box>
                                </HStack>
                            </Box>
                            <Box width="100%">
                                <HStack>
                                    <Box width={80}>
                                        <MoistureParameter autoplant={autoplant} />
                                    </Box>
                                    <Box width={"100%"}>
                                        <Text> Soil moisture: {autoplant.parameter_status?.moisture} </Text>
                                    </Box>
                                </HStack>
                            </Box>
                            <Box width="100%">
                                <HStack>
                                    <Box width={80}>
                                        <NutrientsParameter autoplant={autoplant} />
                                    </Box>
                                    <Box width={"100%"}>
                                        <Text> Nutrients: {autoplant.parameter_status?.nutrients} </Text>
                                    </Box>
                                </HStack>
                            </Box>
                        </VStack>
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={() => navigate(`/autoplant?autoplant-id=${autoplant.device_data.id}#watering`)}>
                        Configure
                    </Button>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export const EnvironmentModal = ({ isOpen, onClose, autoplant }: any) => {
    const navigate = useNavigate();
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg={useColorModeValue("white", "gray.900")}>
                <ModalHeader>Environment</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box
                        textAlign={'center'}
                        width="100%"
                    >
                        <VStack
                            width="100%"
                            fontSize={"md"}
                            align="stretch"
                            divider={
                                <StackDivider
                                    borderColor={useColorModeValue('gray.200', 'gray.600')}
                                />
                            }>
                            <Box width="100%">
                                <HStack>
                                    <Box width={80}>
                                        <TemperatureParameter autoplant={autoplant} />
                                    </Box>
                                    <Box width={"100%"}>
                                        <Text>Temperature: {autoplant.parameter_status?.temperature} </Text>
                                    </Box>
                                </HStack>
                            </Box>
                            <Box width="100%">
                                <HStack>
                                    <Box width={80}>
                                        <HumidityParameter autoplant={autoplant} />
                                    </Box>
                                    <Box width={"100%"}>
                                        <Text>Humidity: {autoplant.parameter_status?.humidity} </Text>
                                    </Box>
                                </HStack>
                            </Box>
                            <Box width="100%">
                                <HStack>
                                    <Box width={80}>
                                        <LightParameter autoplant={autoplant} />
                                    </Box>
                                    <Box width={"100%"}>
                                        <Text>Light: {autoplant.parameter_status?.light} </Text>
                                    </Box>
                                </HStack>
                            </Box>
                        </VStack>
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={() => navigate(`/autoplant?autoplant-id=${autoplant.device_data.id}#environment`)} >
                        Configure
                    </Button>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export const WateringModal = ({ isOpen, onClose, autoplant }: any) => {
    const navigate = useNavigate();
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg={useColorModeValue("white", "gray.900")}>
                <ModalHeader>Watering</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box
                        textAlign={'center'}
                        width="100%"
                    >
                        <VStack
                            width="100%"
                            fontSize={"md"}
                            align="stretch"
                            divider={
                                <StackDivider
                                    borderColor={useColorModeValue('gray.200', 'gray.600')}
                                />
                            }>
                            <Box width="100%">
                                <HStack>
                                    <Box width={80}>
                                        <ActiveParameter autoplant={autoplant} />
                                    </Box>
                                    <Box width={"100%"}>
                                        <Text> Watering is activated or not </Text>
                                    </Box>
                                </HStack>
                            </Box>
                            <Box width="100%">
                                <HStack>
                                    <Box width={80}>
                                        <LastWateredParameter autoplant={autoplant} />
                                    </Box>
                                    <Box width={"100%"}>
                                        <Text> The last time the plant was watered </Text>
                                    </Box>
                                </HStack>
                            </Box>
                            <Box width="100%">
                                <HStack>
                                    <Box width={80}>
                                        <WateredAmountParameter autoplant={autoplant} />
                                    </Box>
                                    <Box width={"100%"}>
                                        <Text>The amount of watering used last time </Text>
                                    </Box>
                                </HStack>
                            </Box>
                            <Box width="100%">
                                <HStack>
                                    <Box width={80}>
                                        <TotalWateredParameter autoplant={autoplant} />
                                    </Box>
                                    <Box width={"100%"}>
                                        <Text>Total amount of watering since the plant was configured </Text>
                                    </Box>
                                </HStack>
                            </Box>
                            <Box width="100%">
                                <HStack>
                                    <Box width={80}>
                                        <BatteryStatusParameter autoplant={autoplant} />
                                    </Box>
                                    <Box width={"100%"}>
                                        <Text>Battery voltage of the watering system </Text>
                                    </Box>
                                </HStack>
                            </Box>
                        </VStack>
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={() => navigate(`/autoplant?autoplant-id=${autoplant.device_data.id}#watering`)} >
                        Configure
                    </Button>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
