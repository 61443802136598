import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    NumberInput,
    NumberInputField,
    useColorModeValue,
    Center,
    VStack,
    Text,
    UnorderedList,
    ListItem,
    Stack,
    SimpleGrid,
    Spacer,
    Flex,
    Container,
    HStack,
    Select,
} from "@chakra-ui/react";

import {
    CheckCircleIcon, WarningTwoIcon,
} from '@chakra-ui/icons';

import Navbar from '../../components/Navbar';
import useAxios from "../../utils/useAxios";

import { IrrigationControllerTile, IrrigationOverview } from './IrrigationOverview';

interface IDevice {
    id: number;
    device_name: string;
    device_type: string;
}

const ConfigureIrrigation = ({ irrigation }: { irrigation: IrrigationOverview }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [showDescription, setShowDescription] = useState<boolean>(false);
    const [sensor, setSensor] = useState<number | null>(irrigation.user_device_sensor);
    const [mode, setMode] = useState<number>(irrigation.mode);
    const [startTime, setStartTime] = useState<string | null>(irrigation.start_time);
    const [endTime, setEndTime] = useState<string | null>(irrigation.end_time);
    const [period, setPeriod] = useState<number | null>(irrigation.period);
    const [dutyCycle, setDutyCycle] = useState<number | null>(irrigation.duty_cycle);
    const [setPoint, setSetPoint] = useState<number | null>(irrigation.setpoint);
    const [dutyCycleHours, setDutyCycleHours] = useState<number>(irrigation.duty_cycle && irrigation.period ? Math.floor((irrigation.duty_cycle / 2**16*irrigation.period)/3600) : 0);
    const [dutyCycleMinutes, setDutyCycleMinutes] = useState<number>(irrigation.duty_cycle && irrigation.period ? Math.floor((irrigation.duty_cycle / 2**16*irrigation.period)%3600/60) : 0);
    const [dutyCycleSeconds, setDutyCycleSeconds] = useState<number>(
        irrigation.duty_cycle && irrigation.period 
            ? Math.round((irrigation.duty_cycle / 2**16 * irrigation.period) % 60) 
            : 0
    );
    const [dutyCyclePercentage, setDutyCyclePercentage] = useState<number | null>(irrigation.duty_cycle && irrigation.duty_cycle/2**16*100);
    const [usePercentage, setUsePercentage] = useState<boolean>(true);


    const boxColor = useColorModeValue('white', 'gray.900');

    const [hours, setHours] = useState<number>(irrigation.period ? Math.floor(irrigation.period / 3600) : 0);
    const [minutes, setMinutes] = useState<number>(irrigation.period ? Math.floor((irrigation.period % 3600) / 60) : 0);
    const [seconds, setSeconds] = useState<number>(irrigation.period ? irrigation.period % 60 : 0);
    const [devices, setDevices] = useState<IDevice[] | undefined>(undefined);

    const [meterStartDateTime, setMeterStartDateTime] = useState<string | null>(irrigation.water_meter_start_date);

    const toast = useToast();

    const navigate = useNavigate();

    const { id } = useParams();

    const api = useAxios();

    const handleConfigure = async () => {
        try {
            setLoading(true);
            const response = await api.put('/agtech/irrigation/' + id + '/', {
                user_device_sensor: sensor,
                mode,
                start_time: startTime,
                end_time: endTime,
                period,
                duty_cycle: dutyCycle,
                setpoint: setPoint,
                water_meter_start_date: meterStartDateTime,
            });
            toast({
                title: 'Controller configured',
                description: 'The controller has been configured successfully',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            navigate(`/irrigation/`);
        } catch (error: any) {
            setError(error.message);
            toast({
                title: 'Error configuring controller',
                description: error.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handlePeriodChange = () => {
        const totalSeconds = hours * 3600 + minutes * 60 + seconds;
        setPeriod(totalSeconds);
    };

    const handleDutyCycleChange = () => {
        if (usePercentage) {
            // Convert duty cycle percentage to a value between 0 and 2**16
            setDutyCycle(dutyCyclePercentage ? Math.round(dutyCyclePercentage / 100 * 2**16) : 0);        } else {
            const totalSeconds = dutyCycleHours * 3600 + dutyCycleMinutes * 60 + dutyCycleSeconds;
            
            if (!period) {
                toast({
                    title: 'Period not set',
                    description: 'Please set the period before setting the duty cycle',
                    status: 'warning',
                    duration: 4000,
                    isClosable: true,
                });
                return;
            }
            
            if (totalSeconds < period) {
                setDutyCycle(Math.round(totalSeconds / period * 2**16));
            }
            else {
                setDutyCycle(2**16);
            }
        }
    };

    useEffect(() => {
        handlePeriodChange();
    }, [hours, minutes, seconds]);

    useEffect(() => {
        handleDutyCycleChange();
    }, [dutyCycleHours, dutyCycleMinutes, dutyCycleSeconds, dutyCyclePercentage]);

    useEffect(() => {
        // Get devices
        api.get('/frontend/user-devices/')
        .then((response) => {
            console.log('Devices fetched', response.data)
            setDevices(response.data);
        })
        .catch((error) => {
            console.error('Error fetching devices', error);
            toast({
                title: 'Error fetching devices',
                description: error.message,
                status: 'error',
                isClosable: true,
            });
            });
    }, []);

    return (
        <Container p={5} bg={boxColor} borderRadius={8}>
                <VStack>
                    <Heading size="lg">Configure Controller</Heading>
                    <Button variant="outline" size="sm" onClick={() => setShowDescription(!showDescription)}>
                        {showDescription ? "Hide Description" : "View Description"}
                    </Button>
                    {showDescription && (
                        <Box>
                            <Text mt={2}>The irrigation controller supports three modes:</Text>
                            <UnorderedList>
                                <ListItem>Manual: Manual opening and closing</ListItem>
                                <ListItem>Schedule: The controller will be on between a start and end time</ListItem>
                                <ListItem>Auto: The controller will automatically adjust the duration to reach the desired moisture</ListItem>
                            </UnorderedList>
                            <Text>Interval - how often the valve is opened within the given time. More often will consume more power</Text>
                            <Text>Duration - how long the valve should stay open. Supports percentage and time input</Text>
                            <Text>Currently start time and end time only supports whole hours, and minutes will be ignored</Text>
                        </Box>
                    )}
                    <FormControl id="device">
                        <FormLabel>Attached sensor</FormLabel>
                        <Select value={String(sensor)} onChange={(e) => setSensor(parseInt(e.target.value))} placeholder='Select device'>
                            {devices?.map((device) => (
                                <option key={device.id} value={device.id}>{device.device_name}</option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl id="mode">
                        <FormLabel>Mode</FormLabel>
                        <Select value={mode ?? ''} onChange={(e) => setMode(parseInt(e.target.value))}>
                            <option value={0}>Manual</option>
                            <option value={1}>Schedule</option>
                            <option value={2}>Auto</option>
                        </Select>                    
                    </FormControl>
                    {(mode === 1 || mode === 2) && (
                        <>
                            <FormControl id="start_time">
                                <FormLabel>Start Time (UTC)</FormLabel>
                                <Input type="time" value={startTime ?? ''} onChange={(e) => setStartTime(e.target.value)} />
                            </FormControl>
                            <FormControl id="end_time">
                                <FormLabel>End Time (UTC)</FormLabel>
                                <Input type="time" value={endTime ?? ''} onChange={(e) => setEndTime(e.target.value)} />
                            </FormControl>
                            <FormControl id="interval" isRequired>
                                <FormLabel>Interval</FormLabel>
                                <HStack>
                                    <Input maxWidth={"15%"} type="number" placeholder="Hours" value={hours} onChange={(e) => setHours(parseInt(e.target.value))} />
                                    <Text m={2}>hr</Text>
                                    <Input maxWidth={"15%"} type="number" placeholder="Minutes" value={minutes} onChange={(e) => setMinutes(parseInt(e.target.value))} />
                                    <Text m={2}>min</Text>
                                    <Input maxWidth={"15%"} type="number" placeholder="Seconds" value={seconds} onChange={(e) => setSeconds(parseInt(e.target.value))} />
                                    <Text m={2}>sec</Text>   
                                </HStack>                     
                            </FormControl>
                            <FormControl id="duration" isRequired>
                                <FormLabel>Duration</FormLabel>
                                <Select value={usePercentage ? 'percentage' : 'time'} onChange={(e) => setUsePercentage(e.target.value === 'percentage')}>
                                    <option value="percentage">Percentage</option>
                                    <option value="time">Time</option>
                                </Select>
                                {usePercentage ? (
                                    <NumberInput
                                        min={0}
                                        max={100}
                                        isRequired
                                        keepWithinRange
                                        value={dutyCyclePercentage ?? 0}
                                        onChange={(e) => setDutyCyclePercentage(parseInt(e))}
                                    >
                                        <NumberInputField />
                                        </NumberInput>
                                ) : (
                                    <HStack mt={2}>
                                        <Input maxWidth={"15%"} type="number" placeholder="Hours" value={dutyCycleHours} onChange={(e) => setDutyCycleHours(parseInt(e.target.value))} />
                                        <Text m={2}>hr</Text>
                                        <Input maxWidth={"15%"} type="number" placeholder="Minutes" value={dutyCycleMinutes} onChange={(e) => setDutyCycleMinutes(parseInt(e.target.value))} />
                                        <Text m={2}>min</Text>
                                        <Input maxWidth={"15%"} type="number" placeholder="Seconds" value={dutyCycleSeconds} onChange={(e) => setDutyCycleSeconds(parseInt(e.target.value))} />
                                        <Text m={2}>sec</Text>   
                                    </HStack>
                                )}
                            </FormControl>
                        </>
                    )}
                    {mode === 2 && (
                        <FormControl id="setpoint" isRequired>
                            <FormLabel>Setpoint</FormLabel>
                            <Input type="number" value={setPoint ?? ''} onChange={(e) => setSetPoint(parseInt(e.target.value))} />
                        </FormControl>
                    )}

                    {irrigation.flow_measurement && (
                        <FormControl id="meter_start_time">
                            <FormLabel>Water meter start time</FormLabel>
                            <Input type="date" value={meterStartDateTime?.split(' ')[0] ?? ''} onChange={(e) => setMeterStartDateTime(e.target.value)} />
                        </FormControl>
                    )}

                    <Button onClick={() => { handleConfigure(); }}>Save</Button>
                </VStack>
        </Container>
    );
}
const IrrigationController = () => {
    const [controller, setController] = useState<IrrigationOverview>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const toast = useToast();
    const navigate = useNavigate();

    const { id } = useParams();

    const api = useAxios();

    useEffect(() => {
        const fetchController = async () => {
            try {
                const response = await api.get('/agtech/irrigation/' + id);
                setController(response.data);
                setLoading(false);
            } catch (error: any) {
                setError(error.message);
                setLoading(false);
                toast({
                    title: 'Error fetching controller',
                    description: error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        };

        fetchController();
    }, []);

    if (!controller) {
        return (
            <Navbar>
            <Container maxW="container.2xl" py={10}>
                <Heading mb={4}>Irrigation Controller</Heading>
                <Text>Loading...</Text>
            </Container>
            </Navbar>
        );
    }

    return (
            <Navbar>
            <Container maxW="container.2xl" py={10}>
            <Center>
                <VStack spacing={4}>
                <Heading mb={4}>Irrigation Controller</Heading>
                {loading && <Text>Loading...</Text>}
                {error && <Text>{error}</Text>}
                <SimpleGrid columns={{"base": 1}} spacing={4}>
                    <Center>
                    <IrrigationControllerTile key={controller.id} irrigation={controller} />
                    </Center>
                    <ConfigureIrrigation irrigation={controller} />
                </SimpleGrid>
                </VStack>
            </Center>
            </Container>
            </Navbar>
    );
}

export default IrrigationController;