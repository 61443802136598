import React, { useState, useEffect } from 'react';
import { Box, Button, Heading, Input, useColorModeValue, useToast, Container, Text } from '@chakra-ui/react';
import Navbar from '../components/Navbar';
import useAxios from '../utils/useAxios';

interface IPlotSettings {
    moisture_plot_minimum?: number;
    moisture_plot_maximum?: number;
    temperature_plot_minimum?: number;
    temperature_plot_maximum?: number;
}

const SettingsView = () => {

    const [isLoading, setIsLoading] = useState(true);

    const api = useAxios();
    const toast = useToast();
    const [settings, setSettings] = useState<IPlotSettings>({
        moisture_plot_minimum: undefined,
        moisture_plot_maximum: undefined,
        temperature_plot_minimum: undefined,
        temperature_plot_maximum: undefined,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSettings({
            ...settings,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            await api.put('frontend/frontend-settings/', settings);
            toast({
                title: "Settings saved.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        } catch (error: any) {
            toast({
                title: "Error saving settings",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
        setIsLoading(false);
    };

    const fetchCurrentSettings = async () => {
        try {
            const response = await api.get('frontend/frontend-settings/');
            setSettings(response.data);
        } catch (error: any) {
            toast({
                title: "Error fetching settings",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchCurrentSettings();
    }, []);

    const box_color = useColorModeValue('white', 'gray.900');

    return (
        <Navbar>
            <Container mt={2}>
                <Box bg={box_color} boxShadow="lg" borderRadius="md" p={5} mx={{ base: 2, sm: 4, md: 6 }}>
                    <Heading color={useColorModeValue('black', 'gray.100')} fontSize={{ base: 'xl', sm: '2xl' }} mb={2}>
                        Map Settings
                    </Heading>
                    <Text color={useColorModeValue('gray.600', 'gray.300')} fontSize={{ base: 'sm', sm: 'md' }}>
                        Set the minimum and maximum values for the colorscale in the moisture map. You might need to refresh to see the changes.
                    </Text>
                    <Box my={4}>
                        <Input
                            type="number"
                            name="moisture_plot_minimum"
                            value={settings.moisture_plot_minimum}
                            onChange={handleChange}
                            placeholder="Enter minimum moisture"
                            isDisabled={isLoading}
                        />
                    </Box>
                    <Box my={4}>
                        <Input
                            type="number"
                            name="moisture_plot_maximum"
                            value={settings.moisture_plot_maximum}
                            onChange={handleChange}
                            placeholder="Enter maximum moisture"
                            isDisabled={isLoading}
                        />
                    </Box>
                    <Text color={useColorModeValue('gray.600', 'gray.300')} fontSize={{ base: 'sm', sm: 'md' }}>
                        Set the minimum and maximum values for the temperature map. You might need to refresh to see the changes.
                    </Text>
                    <Box my={4}>
                        <Input
                            type="number"
                            name="temperature_plot_minimum"
                            value={settings.temperature_plot_minimum}
                            onChange={handleChange}
                            placeholder="Enter minimum temperature"
                            isDisabled={isLoading}
                        />
                    </Box>
                    <Box my={4}>
                        <Input
                            type="number"
                            name="temperature_plot_maximum"
                            value={settings.temperature_plot_maximum}
                            onChange={handleChange}
                            placeholder="Enter maximum temperature"
                            isDisabled={isLoading}
                        />
                    </Box>
                    <Button colorScheme="teal" onClick={handleSave} isLoading={isLoading}>
                        Save Settings
                    </Button>
                </Box>
            </Container>

        </Navbar>
    );
};

export default SettingsView;