import {
    Text,
    Flex,
    Heading,
    Stack,
    Button,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";

import { useColorModeValue } from "@chakra-ui/react";

const NotImplemented = () => {
    const navigate = useNavigate();
    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}
        >
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'}>Not implemented</Heading>
                    <Text fontSize={'lg'} color={'gray.600'}>
                        This page is not implemented yet.
                    </Text>
                    <Button
                        color={'green.400'}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        Go back
                    </Button>
                </Stack>
            </Stack>
        </Flex>
    );
}

export default NotImplemented;