import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  useToast,
} from '@chakra-ui/react';
import { useState, useContext } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import AuthContext from "../../context/AuthContext";

// Define TypeScript interface for the response prop
interface ErrorMessagesProps {
  response: Record<string, string[]> | string;
}

const ErrorMessages: React.FC<ErrorMessagesProps> = ({ response }) => {
  // Check if response is an object and has keys
  if (typeof response === 'object' && response !== null) {
    return (
      <Box>
        {Object.keys(response).map((key) => (
          <Box key={key} mb={4}>
            {response[key].map((error, index) => (
              <Text color={'red.400'} key={index}>
                {`${key.charAt(0).toUpperCase() + key.slice(1)}: ${error}`}
              </Text>
            ))}
          </Box>
        ))}
      </Box>
    );
  }

  // Fallback in case the response is not in the expected format
  return <Text color={'red.400'}>{typeof response === 'string' ? response : JSON.stringify(response)}</Text>;
};


export default function SignupCard() {
  const [showPassword, setShowPassword] = useState(false);

  const { registerUser } = useContext(AuthContext);
  const [response, setResponse] = useState({});


  const toast = useToast();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const email = e.target.email.value;
    const first_name = e.target.first_name.value;
    const last_name = e.target.last_name.value;
    const password = e.target.password.value;
    const password2 = password; // Replace with e.target.password2.value if implemented
    if (email.length > 0) {
      const response = await registerUser(email, first_name, last_name, password, password2);
      if (response.status >= 400) {
        // Failed to register user
        console.log(response);
        const responseBody = await response.json(); // Read the JSON body
        setResponse(responseBody); // Assuming you want to store the response body in state
  
        toast({
          title: "Error registering user",
          description: "Failed to register user. Please check the form and try again.",
          status: "error"
        });
      }
      else {
        // User registered successfully

        toast({
          title: "User registered successfully",
          description: "User registered successfully. Please check your email for a verification link.",
          status: "success"
        });
    }
  }
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.900')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            Sign up
          </Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            Go to our <Link color={'gray.400'} href='https://botanist.no'>landing page </Link> to read more about our services.
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          as={'form'}
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'lg'}
          p={8}
          onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <HStack>
              <Box>
                <FormControl id="first_name" label="first_name" isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input type="text" />
                </FormControl>
              </Box>
              <Box>
                <FormControl id="last_name" label='last_name' isRequired>
                  <FormLabel>Last Name</FormLabel>
                  <Input type="text" />
                </FormControl>
              </Box>
            </HStack>
            <FormControl id="email" label="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input type="email" />
            </FormControl>
            <FormControl id="password" label="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input type={showPassword ? 'text' : 'password'} />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Button
                loadingText="Submitting"
                size="lg"
                bg={'green.600'}
                color={'white'}
                _hover={{
                  bg: 'blue.700',
                }}
                type="submit">
                Sign up
              </Button>
              {response && (
                <ErrorMessages response={response} />
              )}
            </Stack>
            <Stack pt={6}>
              <Text align={'center'}>
                Already a user? <Link href="login" color={'green.400'}>Login</Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
