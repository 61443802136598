import axios from "axios";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const baseURL = process.env.REACT_APP_API_URL + "api/v1/";

const useAxios = () => {
    // Simplify requests for when authentication is added
    const { authTokens, setAuthTokens } = useContext(AuthContext);

    console.log(baseURL)

    const axiosInstance = axios.create({
        baseURL,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${localStorage.getItem("token")}`,
        },

    });

    return axiosInstance;
};


export default useAxios;
