import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {useControl} from 'react-map-gl';

import {useRef} from 'react';

import type {MapRef, ControlPosition} from 'react-map-gl';


type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  onLoad?: (draw: MapboxDraw | null) => void;
  onCreate?: (evt: {features: object[]}) => void;
  onUpdate?: (evt: {features: object[]; action: string}) => void;
  onDelete?: (evt: {features: object[]}) => void;
};

export default function DrawControl(props: DrawControlProps) {
  const mapDraw = useRef<MapboxDraw | null>(null);

  const handleLoad = props.onLoad || (() => {});
  const handleCreate = props.onCreate || (() => {});
  const handleUpdate = props.onUpdate || (() => {});
  const handleDelete = props.onDelete || (() => {});

  useControl<MapboxDraw>(
    () => {
      const draw = new MapboxDraw(props);
      mapDraw.current = draw;
      return draw;
    },
    (context: any) => {
      const map = context.map.getMap();
      map.on('load', () => handleLoad(mapDraw.current));
      map.on('draw.create', handleCreate);
      map.on('draw.update', handleUpdate);
      map.on('draw.delete', handleDelete);
    },
    (context: any) => {
      const map = context.map.getMap();
      map.off('load', () => handleLoad);
      map.off('draw.create', handleCreate);
      map.off('draw.update', handleUpdate);
      map.off('draw.delete', handleDelete);
    },
    {
      position: props.position
    }
  );

  return null;
}

DrawControl.defaultProps = {
  onCreate: () => {},
  onUpdate: () => {},
  onDelete: () => {}
};