import { Select } from "chakra-react-select";

import { useState, useEffect } from "react";

import useAxios from "../../utils/useAxios";


const SelectPlantSpecies = ({ selectedSpecies, setSelectedSpecies, ...props }: any) => {


    const [allSpecies, setAllSpecies] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const axios = useAxios();

    useEffect(() => {
        const fetchSpecies = async () => {
            const response = await axios.get("users/autoplant-devices/preset-watering-params/");
            setAllSpecies(response.data);
            setIsLoading(false);
        };
        fetchSpecies();
    }, []);

    return (
        <Select
            placeholder="Select species"
            isDisabled={isLoading}
            defaultValue={selectedSpecies}
            value={selectedSpecies}
            onChange={setSelectedSpecies}
            options={allSpecies.map((species: any) => ({
                label: species.plant_name,
                value: species.id,
            }))}
            {...props}
        />
    );
};


export default SelectPlantSpecies;