import { Container, Text, Center, Icon, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaSeedling } from "react-icons/fa";

const animation = {
  scale: [1, 2, 2, 1, 1],
  rotate: [0, 0, 270, 270, 0],
  borderRadius: ["20%", "20%", "50%", "50%", "20%"],
  transition: {
    duration: 2,
    ease: "easeInOut",
    repeat: Infinity,
    repeatType: "loop" as const
  }
};

const MotionBox = motion.div;

export default function FancyPageLoadingAnymation() {
  return (
    <Container h="100vh" display="flex" alignItems="center" justifyContent="center">
      <MotionBox
        animate={animation}
        style={{ padding: "2", width: "20", height: "20", display: "flex" }}
      >
        <Center>
          <VStack>
            <Icon boxSize="5" color="white" as={FaSeedling} />
            <Text color="white" fontSize="sm" fontWeight="bold">
              Loading...
            </Text>
          </VStack>
        </Center>
      </MotionBox>
    </Container>
  )
};