import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';

import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
    Skeleton,
    Progress,
    Select,
} from "@chakra-ui/react"

import Map from 'react-map-gl';
import { Source, Layer, GeolocateControl, ScaleControl, NavigationControl } from 'react-map-gl';

import useAxios from "../../utils/useAxios";

import { Field } from '../../types/fieldInterface';
import { IDevice } from '../DeviceMap';
import { RenderDevices, calculateMoistureColor, calculateTemperatureColor, TemperatureGradient } from '../DeviceMap';
import { Gradient } from '../DeviceMap';
import authContext from '../../context/AuthContext';



function RenderPolygon({field } : {field: Field}) {
    if (!field.area) {
        return null;
    }
    return (
      <Source
        id="polygon"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: Object.values(field.area).map(area => ({
            ...area,
            type: 'Feature',
            geometry: {
              ...area.geometry,
              type: 'Polygon', // or 'MultiPolygon', etc., depending on the actual type
            },
          })),
        }}
      >
        <Layer
          id="polygon"
          type="line"
          paint={{
            'line-color': '#088',
            'line-width': 2,
          }}
        />
        <Layer
          id="polygon-fill"
          type="fill"
          paint={{
            'fill-color': '#088',
            'fill-opacity': 0.2,
          }}
        />
      </Source>
    );
  }

  const MoistureCategories = ({ field }: { field: Field }) => {
    const textColor = useColorModeValue('gray.600', 'gray.400');
    return (
      <Box textColor={textColor} mb={2}>
        {field?.moisture_limit_critical && field?.moisture_limit_damage && field?.moisture_limit_deficit && field?.moisture_limit_field_capacity ? (
          <Box display="flex" alignItems="center" height="50px" position="relative">
            <Box display="flex" flexDirection="column" width="20%">
              <Text fontSize="sm" textAlign="right" position={"relative"} right={"-16px"}>{field.moisture_limit_critical}%</Text>
              <Box height="20px" bg="black" width="100%" />
              <Text fontSize="sm" textAlign={"center"}>PWP</Text>
            </Box>
            <Box display="flex" flexDirection="column" width="20%">
                <Text fontSize="sm" textAlign="right" position={"relative"} right={"-16px"}>{field.moisture_limit_damage}%</Text>
              <Box height="20px" bg="red" width="100%" />
              <Text fontSize="sm" textAlign={"center"}>Damage</Text>
            </Box>
            <Box display="flex" flexDirection="column" width="20%">
                <Text fontSize="sm" textAlign="right" position={"relative"} right={"-16px"}>{field.moisture_limit_deficit}%</Text>
              <Box height="20px" bg="rgb(255, 165, 0)" width="100%" />
              <Text fontSize="sm" textAlign={"center"}>Deficit</Text>
            </Box>
            <Box display="flex" flexDirection="column" width="20%">
                <Text fontSize="sm" textAlign="right" position={"relative"} right={"-16px"}>{field.moisture_limit_field_capacity}%</Text>
              <Box height="20px" bg='rgb(0, 255, 0)' width="100%" />
              <Text fontSize="sm" textAlign={"center"}>Good</Text>
            </Box>
            <Box display="flex" flexDirection="column" width="20%">
                <Text fontSize="sm" textAlign="right">-</Text>
              <Box height="20px" bg="blue" width="100%" />
              <Text fontSize="sm" textAlign={"center"}>Runoff</Text>
            </Box>
          </Box>
        ) : (
          <Text mb={4}>All the moisture levels are not set. Go to fields, then edit to set the tresholds.</Text>
        )}
      </Box>
    );
  };

enum ColorScaleTypes {
    MoistureGradient = "MoistureGradient",
    MoistureLevelCategory = "MoistureLevelCategory",
    TemperatureGradient = "TemperatureGradient",
    SoilTemperatureGradient = "SoilTemperatureGradient",
    ECGradient = "ECGradient", // Not implemented yet
}

type CalculateColorFunction = (value: number | undefined, params: Record<string, number>) => string;

const CalculateMoistureCategoryColor: CalculateColorFunction = (value, params) => {
    const criticalColor = 'black';
    const damageColor = 'red';
    const deficitColor = 'rgb(255, 165, 0)'; // Orange
    const fieldCapacityColor = 'rgb(0, 255, 0)'; // Green
    const aboveFieldCapacityColor = 'blue';

    if (value === undefined || params === undefined) {
        return criticalColor; // Return black if value or params are undefined
    }

    const { moisture_limit_critical, moisture_limit_damage, moisture_limit_deficit, moisture_limit_field_capacity } = params;

    if (moisture_limit_critical === undefined || moisture_limit_damage === undefined || moisture_limit_deficit === undefined || moisture_limit_field_capacity === undefined) {
        return criticalColor; // Return black if any limit is undefined
    }

    if (value <= moisture_limit_critical) {
        return criticalColor;
    } else if (value <= moisture_limit_damage) {
        return damageColor;
    } else if (value <= moisture_limit_deficit) {
        return deficitColor;
    } else if (value <= moisture_limit_field_capacity) {
        return fieldCapacityColor;
    } else {
        return aboveFieldCapacityColor;
    }
};


const FieldMap = ({field_id } : {field_id: number}) => {
    // This will be the base-map for the field.
    // It will also control the overlay options for the field like:
    // - Field boundary
    // - Sensors
    // - And more in the future

    const toast = useToast();
    const api = useAxios();

    const [field, setField] = useState<Field | null>(null);
    const [devices, setDevices] = useState<IDevice[]>([]);

    const [isLoadingMap, setIsLoadingMap] = useState(true);
    const [isLoadingDevices, setIsLoadingDevices] = useState(true);

    const { userSettings } = useContext(authContext);
    const [ColorScaleType, setColorScaleType] = useState<ColorScaleTypes>(ColorScaleTypes.MoistureGradient);

    const [initialViewState, setInitialViewState] = useState({
        latitude: 37.7749,
        longitude: -122.4194,
        zoom: 10,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`agtech/fields/${field_id}/`)
                setField(response.data);
                console.log(response.data);
                // Set the initial view state of the map
                if (response.data.field_center_lat && response.data.field_center_lon) {
                    console.log("Setting initial view state")
                    setInitialViewState({
                        latitude: response.data.field_center_lat,
                        longitude: response.data.field_center_lon,
                        zoom: 15,
                    });
                }

            } catch (error: any) {
                setField(null);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    description: error.response.data?.detail,
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        const fetchDevices = async () => {
            try {
                const response = await api.get("frontend/devices/current-data/");
                console.log(response.data);
                setDevices(response.data);

                setIsLoadingDevices(false);
            } catch (error: any) {
                if (error.response.status === 401) {
                    // Currently, this is handled elsewhere
                    return;
                }
                setDevices([]);
                setIsLoadingDevices(false);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        };

        fetchData();
        fetchDevices();
    }, []);

    useEffect(() => {
        if (field) {
            setIsLoadingMap(false);
            console.log(field.area)
        }
        if (field) {
            // Check if the all the moisture limits are set, if not, set category to gradient
            if (!field.moisture_limit_critical || !field.moisture_limit_damage || !field.moisture_limit_deficit || !field.moisture_limit_field_capacity) {
                setColorScaleType(ColorScaleTypes.MoistureGradient);
            }
        }
    }, [field]);
 
    return (
        <>
        {isLoadingMap ? (
            <Skeleton isLoaded={false}>
            <Box width="100%" height={{ base: 400, md: 500, lg: 700 }}>
                Loading map...
            </Box>
            </Skeleton>
        ) : (
        <Box width="100%" height={{ base: 400, md: 500, lg: 700 }}  display="flex" flexDirection="column">
            <Map
                initialViewState={initialViewState}
                mapStyle="mapbox://styles/mapbox/satellite-v9"
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                style={{ width: "100%", height: "100%" }}
                mapLib={import('mapbox-gl')}
                
                terrain={{source: 'mapbox-dem', exaggeration: 1.5}}
            >
                <Source
                    id="mapbox-dem"
                    type="raster-dem"
                    url="mapbox://mapbox.mapbox-terrain-dem-v1"
                    tileSize={512}
                    maxzoom={14}
                />

                {!!field && <RenderPolygon field={field} />}
                {devices.length > 0 && field && (
                    <>                    
                        { ColorScaleType === ColorScaleTypes.MoistureGradient && (
                            <RenderDevices
                                devices={devices}
                                plotkey='soil_moisture'
                                color_function={calculateMoistureColor}
                                color_params={{min: userSettings.moisture_plot_minimum, max: userSettings.moisture_plot_maximum}}
                            />
                        )}
                        { ColorScaleType === ColorScaleTypes.MoistureLevelCategory && (
                            <RenderDevices
                                devices={devices}
                                plotkey='soil_moisture'
                                color_function={CalculateMoistureCategoryColor}
                                color_params={{
                                    moisture_limit_critical: field.moisture_limit_critical || 0,
                                    moisture_limit_damage: field.moisture_limit_damage || 0,
                                    moisture_limit_deficit: field.moisture_limit_deficit || 0,
                                    moisture_limit_field_capacity: field.moisture_limit_field_capacity || 0,
                                }}
                            />
                        )}
                        { ColorScaleType === ColorScaleTypes.TemperatureGradient && (
                            <RenderDevices devices={devices} color_function={calculateTemperatureColor} plotkey='temperature' color_params={{min: userSettings.temperature_plot_minimum, max: userSettings.temperature_plot_maximum}} />
                        )}
                        { ColorScaleType === ColorScaleTypes.SoilTemperatureGradient && (
                            <RenderDevices devices={devices} color_function={calculateTemperatureColor} plotkey='soil_temperature' color_params={{min: userSettings.temperature_plot_minimum, max: userSettings.temperature_plot_maximum}} />
                        )}
                    </>
                )}
                <GeolocateControl
                    positionOptions={{ enableHighAccuracy: true }}
                    trackUserLocation={true}
                    showUserLocation={true}
                />
                <ScaleControl/>
                <NavigationControl position="top-left" />
            </Map>
            <Center>

                <Box width={{ base: "90%", md: "50%" }} mt={4}>
                { isLoadingDevices || !field ? (
                    <>
                        <Progress size="xs" isIndeterminate />
                        <Text textAlign={"center"}>Loading device data...</Text>
                    </>
                ) : (
                    <>
                    { ColorScaleType === ColorScaleTypes.MoistureGradient && (
                        <Gradient />
                    )}
                    { ColorScaleType === ColorScaleTypes.MoistureLevelCategory && (
                        <MoistureCategories field={field} />
                    )}
                    { ColorScaleType === ColorScaleTypes.TemperatureGradient && (
                        <TemperatureGradient />
                    )}
                    { ColorScaleType === ColorScaleTypes.SoilTemperatureGradient && (
                        <TemperatureGradient />
                    )}

                        <Select value={ColorScaleType} onChange={(e) => setColorScaleType(e.target.value as ColorScaleTypes)}>
                            <option value={ColorScaleTypes.MoistureGradient}>Moisture Gradient </option>
                            <option value={ColorScaleTypes.MoistureLevelCategory}>Moisture Status</option>
                            <option value={ColorScaleTypes.TemperatureGradient}>Air Temperature Gradient</option>
                            <option value={ColorScaleTypes.SoilTemperatureGradient}>Soil Temperature Gradient</option>
                        </Select>
                    </>
                )}
                </Box>
            </Center>
        </Box>
    )}
    </>
    )
}

export default FieldMap;